import Vue from 'vue'
import Router from 'vue-router'

import login from "@/components/login"
import showlist from '../components/show.list'
import ShowEdit from '../components/show.edit'
import ShowBasicEdit from '../components/editors/show'

import ShowIndex from '../components/show.index'
import ShowLanding from '../components/show.landing'
import PushNotificationPage from '../components/push.notification.list.vue'

import AttendeeCategoryTwoPanelEditor from '../components/attendee.category.two.panel.vue'
import VendorTwoPanelEditor from '../components/vendor.details.two.panel.vue'

import PollsTwoPanelSelector from '../components/polls.two.panel.vue'

import RegistrationTwoPanelSelector from '../components/registration.two.panel.vue'
import DiscussionAreaList from '../components/discussion.area.list.vue'
import GenericLanding from '../components/generic.landing.vue'
import CompanyIntegration from '../components/company.integrations.vue'
import VendorCategories from '../components/editors/vendorcategory.vue'

import SessionCategories from '../components/editors/sessioncategory.vue'

import NotificationTemplate from '../components/notification.template.list'
import NotificationCampaign from '../components/notification.campaign.list'

import { requireAuth } from '../components/authentication/token'

import SessionCalendar from '../components/session.calendar'

import ShowWizard from '../components/show-wizard/show.wizard.host'
import ThemeEditor from '../components/show.theme.editor.vue'
import AttendeeBatch from '../components/attendee.batch.edit'

import ConvertPollToPage from '../components/poll.transfer.to.page'
import ZoomConfigurator from '../components/zoom.configurator'
import FavoriteEditor from '../components/favorite.editor'
import IcalInviteConsole from '../components/ical.invite.console.vue'

import BookableVendors from '../components/bookable.vendors.vue'

import ShowRundown from '../components/show.rundown.vue'
import WebhookList from '../components/webhook.list.vue'
import InboundWebhookList from '../components/inbound.webhook.list.vue'
import VideoList from '../components/video.list.vue';
import EmailSetup from '../components/email.setup.vue'
import ShowEmailSetup from '../components/show.email.setup.vue'
import PublicPollResultsList from '../components/public.poll.results.list.vue'
import MuxStreamList from '../components/mux.stream.list.vue'
import MuxAssetList from '../components/mux.asset.list.vue'
import ShortUrlMasterList from '../components/short.url.master.list.vue'

Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'HelloWorld',
    //   component: HelloWorld
    // },
    {
      path: '/integrate',
      name: 'integrate',
      props: true,
      beforeEnter: requireAuth,
      component: CompanyIntegration
    },
    {
        path: '/shorturls',
        name: 'shorturlsmaster',
        props: true,
        beforeEnter: requireAuth,
        component: ShortUrlMasterList
      },
      {
        path: '/email',
        name: 'EmailSetup',
        props: true,
        beforeEnter: requireAuth,
        component: EmailSetup  
    },
    {
        path: '/pollresults/:showCode/:pollCode/:code',
        name: "PublicPollResults",
        props: true,
        component: PublicPollResultsList
    },
    {
      path: '/shows/:showCode',
      component: ShowIndex,
      props: true,
      beforeEnter: requireAuth,
      children: [
        {
            path: 'replays',
            name: "MuxAssets",
            props: true,
            beforeEnter: requireAuth,
            component: MuxAssetList
        },
        {
            path: 'streams',
            name: "StreamList",
            props: true,
            beforeEnter: requireAuth,
            component: MuxStreamList
        },
        {
            path: 'emailsetup',
            name: 'ShowEmailSetup',
            props: true,
            beforeEnter: requireAuth,
            component: ShowEmailSetup
        },
        {
            path: 'webhooks',
            name: 'WebHooksList',
            component: WebhookList,
            beforeEnter: requireAuth,
            props: true
        },
        {
            path: 'inboundwebhooks',
            name: 'InboundWebHooksList',
            component: InboundWebhookList,
            beforeEnter: requireAuth,
            props: true
        },
        {
            path: 'rundown',
            name: 'ShowRundown',
            component: ShowRundown,
            beforeEnter: requireAuth,
            props: true,
        },
        {
            path: 'videos',
            name: 'VideoList',
            component: VideoList,
            beforeEnter: requireAuth,
            props: true,
        },
        {
            path: 'favorites',
            name: 'FavoriteEditor',
            component: FavoriteEditor,
            beforeEnter: requireAuth,
            props: true,
        },
        {
            path: 'icalinvites',
            name: 'IcalInvites',
            component: IcalInviteConsole,
            beforeEnter: requireAuth,
            props: true,
        },
        {
            path: 'zoom',
            name: 'ZoomConfigurator',
            component: ZoomConfigurator,
            beforeEnter: requireAuth,
            props: true,
        },
        {
            path: 'topage/:pollCode/:responseCode',
            name: 'ConvertPollToPage',
            component: ConvertPollToPage,
            beforeEnter: requireAuth,
            props: true  
        },
        {
            path: 'theme',
            name: "ThemeEditor",
            props: true,
            beforeEnter: requireAuth,
            component: ThemeEditor
        },
        {
          path: 'appNotifications',
          name: 'AppNotifications',
          beforeEnter: requireAuth,
          component: PushNotificationPage
        },
        {
          path: '',
          name: 'ShowEdit',
          beforeEnter: requireAuth,
          component: ShowLanding
        },
        {
          path: 'edit',
          name: 'ShowEditDetails',
          beforeEnter: requireAuth,
          component: ShowEdit
        },
        {
          path: 'basics',
          name: 'ShowBasics',
          component: ShowBasicEdit,
          props: true,
          beforeEnter: requireAuth
        },
        {
          path: 'emails/campaigns',
          name: 'campaign',
          beforeEnter: requireAuth,
          component: NotificationCampaign
        },
        {
          path: 'emails/templates',
          name: 'template',
          beforeEnter: requireAuth,
          component: NotificationTemplate
        },
        {
          path: 'attendeebatch',
          name: 'attendeebatch',
          beforeEnter: requireAuth,
          component: AttendeeBatch
        },
        {
          path: 'registration',
          name: 'registration',
          beforeEnter: requireAuth,
          component: RegistrationTwoPanelSelector
        },
        {
          path: 'attendees/categories',
          name: 'attendeecategory',
          beforeEnter: requireAuth,
          component: AttendeeCategoryTwoPanelEditor
          // component: AttendeeCategory
        },
        {
          path: 'polls',
          name: 'polls',
          beforeEnter: requireAuth,
          component: PollsTwoPanelSelector
        },
        {
          path: 'chats',
          name: 'chats',
          beforeEnter: requireAuth,
          component: DiscussionAreaList
        },
        {
          path: 'sessions',
          name: 'sessionLanding',
          beforeEnter: requireAuth,
          component: SessionCalendar
        },
        {
          path: 'sessions/calendar',
          name: 'sessionCalendar',
          beforeEnter: requireAuth,
          component: SessionCalendar
        },
        {
          path: 'pages',
          name: 'vendorLanding',
          beforeEnter: requireAuth,
          component: GenericLanding
        },
        //todo change this to vendorEditor
        {
          path: 'pages/categories',
          name: 'vendorCategories',
          beforeEnter: requireAuth,
          component: VendorCategories
        },
        {
          path: 'pages/details',
          name: 'vendorsDetails',
          beforeEnter: requireAuth,
          component: VendorTwoPanelEditor
        },
        {
          path: 'publicEventInfo',
          name: 'publicEventInfo',
          beforeEnter: requireAuth,
          component: GenericLanding
        },
        {
          path: 'bookableAdmin',
          name: 'bookableAdmin',
          beforeEnter: requireAuth,
          component: BookableVendors
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: login
    },
    {
      path: '/shows',
      name: 'ShowList',
      component: showlist,
      beforeEnter: requireAuth
    },
    {
      path: '/showwizard',
      name: "ShowWizard",
      beforeEnter: requireAuth,
      component: ShowWizard
    },        
    {
      path: '*',
      component: login
    }
  ]
})
