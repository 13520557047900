<template>
    <loading v-if="submitting">
    </loading>
    <div v-else>
        <div class="row" v-if="validateErrors && validateErrors.length > 0">
            <div class="mt-1 alert alert-danger text-start" role="alert">
                    Oops! It looks like something happened. Please double-check and try again.
                    <ul>
                        <li v-for="error in validateErrors">
                            {{ error }}
                        </li>
                    </ul>
            </div>
        </div>
        <AttendeeListSelector :showSidebar="selectedElement"
                              :showSignInLink="true"
                              :showDetails="showDetails"
                              :attendees="attendees"
                              :showCode="showCode"
                              :selectedElement="selectedElement"
                              @select-attendee="selectAttendee"
                              @custom-event="onAttendeeCustomEvent"
                              :customColumns="customColumns"
                              v-if="!submitting"
                              ref="attendeeList">
            <template v-slot:mainbuttons>
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" @click="addAttendee">
                        <i class="bi bi-plus-lg"></i>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="onDownloadRequest">
                        <i class="bi bi-file-earmark-excel-fill"></i>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="onDownloadRequestCsv">
                        <i class="bi bi-filetype-csv"></i>
                    </button>
                    <button type="button" class="btn btn-primary" @click="startUploadCsv">
                        <i class="bi bi-file-earmark-arrow-up"></i>
                    </button>
                    <button type="button" @click="onSave(null)" class="btn btn-success">Save</button>
                    <button type="button" @click="onSaveAndEmail" class="btn btn-success">Save &amp; Email</button>
                    <input type="file" style="display:none;" @change="fileChange($event.target.name, $event.target.files)" ref="csvFile">
                    <button class="btn btn-primary"
                                @click="build">
                        <i class="bi bi-arrow-repeat">
                        </i>
                        Refresh
                    </button>
                </div>
            </template>
            <template v-slot:sidebar>
                <attendee-editor :show="showDetails"
                                 :attendee="selectedElement"
                                 :doNotSave="true"
                                 @saved="onSaveAttendee"
                                 @cancel="selectAttendee(null)"
                                 :destinations="tractusLinkDestinations">
                </attendee-editor>                
            </template>
        </AttendeeListSelector> 
        <div class="modal fade"
             ref="templateDialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Save and Email
                        </h5>
                        <button type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">

                        </button>
                    </div>

                    <div class="modal-body">
                        <label>Select Template</label>

                        <select class="form-select"
                                v-model="selectedTemplateCode">
                            <option :value="null">
                                Select a Template...
                            </option>
                            <option v-for="template in templates"
                                    :key="template.Code"
                                    :value="template.Code">
                                {{template.Subject}}
                            </option>
                        </select>
                    </div>

                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-primary"
                                @click="onConfirmEmail">
                            Save and Send to New Attendees ({{newAttendeeCount}})
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="flashDialog"
             ref="flashCsvDialog"
             class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Import Attendees ({{toImport.length}})</h5>                    
                        <button type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        CSV Column
                                    </th>
                                    <th>
                                        Mapped To...
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="col in columnsToMap">
                                    <td>
                                        {{col.ColumnName}}
                                    </td>
                                    <td>
                                        <select class="form-control" v-model="col.TypeCode">
                                            <option v-for="category in columnTypes" :value="category.Code">
                                                {{category.Name}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>Import Attendees into Categories</h6>
                        <div class="row">
                            <div class="col-md-4" v-for="category in categoriesToSet" :key="category.Code">
                                <div class="form-check">
                                    <label class="form-check-label" :for="category.Code">
                                        <input class="form-check-input" type="checkbox" v-model="category.Selected" :id="category.Code">
                                        {{category.Name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-primary"
                                @click="flashToCsv">
                            Import
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import AttendeeAdditionalInfo from '../models/AttendeeAdditionalInfo'
import Attendee from '../models/Attendee'
import * as Papa from 'papaparse'
import { getAttendeeAppUrl } from './environment.hci'
import { Modal } from 'bootstrap'

import AttendeeListSelector from './attendee.list.selector.vue'

import ExcelJS from 'exceljs'

export default {
    props: ['showDetails', 'showCode'],

    components: { 'AttendeeListSelector': AttendeeListSelector },

    mixins: [
        Token,
        Common
    ],   

    computed: {
        categoryFilters() {
            return this.$refs.attendeeList.categoryFilters;
        },

        filteredList() {
            return this.$refs.attendeeList.filteredList;
        },

        newAttendeeCount() {
            return this.attendees ? this.attendees.filter(x => !x.Code || x.Code == '').length : 0;
        }
    },

    methods: {  
        async onAttendeeCustomEvent(eventName, attendee) {
            if(eventName == 'delete') {

                if(!confirm(`Are you sure you want to delete ${attendee.Code}?`)) {
                    return;
                }

                this.submitting = true;
                try {
                    let r = await this.tryDelete(`/api/show/${this.showCode}/attendee/${attendee.Code}`);
                        
                    if(r.Errors && r.Errors.length > 0) {
                        throw r.Errors[0];
                    }          

                } catch(ex) {

                    alert("Could not delete attendee.");
                    console.error(ex);

                }

                await this.build();
            }
        },

        onSaveAndEmail() {
            this.getTemplates();

            this.templateModal = new Modal(this.$refs.templateDialog);

            this.templateModal.show();
        },

        onConfirmEmail() {
            if(!this.selectedTemplateCode) {
                alert("No template was selected.");
                return;
            }

            this.templateModal.hide();

            this.onSave(this.selectedTemplateCode);
        },

        async getTemplates() {
            try{
                let r = await this.tryGet(`/api/communications/templates/${this.showCode}`);
                this.templates = r.Result;
            }
            catch(ex){
                console.log(ex);
            }            
        },

        selectAttendee(attendee) {
            this.selectedElement = null;

            if(!attendee) {
                return;
            }
            
            Vue.nextTick(() => this.selectedElement = attendee);
        },

        async onDownloadRequestCsv() {
            let output = 'Code,FirstName,LastName,DisplayName,Email,Title,Subtitle,Pin,ShowCode,SignInUrl,SignInUrlBadge,ScanQrCode,CategoryCodes\n';

            let toWrite = this.filteredList;

            // ChatGPT to the rescue
            const escapeAndQuote = (value) => {
                if (value == null) value = ''; // Handle null or undefined values
                // Replace any existing quotes with double quotes
                value = value.toString().replace(/"/g, '""');
                // Encapsulate the value in quotes
                return `"${value}"`;
            };

            toWrite.forEach((a, i) => {
                let signInUrl = `${getAttendeeAppUrl()}/login?l=${btoa(`${a.Code}:${a.SignInPin}:${this.showDetails.Code}`)}`;
                let signInUrlBadge = `${getAttendeeAppUrl()}/login?s=${this.showCode}&a=${a.Code}`;
                let scanQrCode = `tractus://attendee?s=${this.showCode}&c=${a.Code}`;
                let categoryCodes = a.Categories.map(c => c.Code).join(';');

                output += `${escapeAndQuote(a.Code)},${escapeAndQuote(a.FirstName)},${escapeAndQuote(a.LastName)},${escapeAndQuote(a.DisplayName)},${escapeAndQuote(a.PrimaryEmail)},${escapeAndQuote(a.ContactInfo.Title)},${escapeAndQuote(a.ContactInfo.SubTitle)},${escapeAndQuote(a.SignInPin)},${escapeAndQuote(this.showCode)},${escapeAndQuote(signInUrl)},${escapeAndQuote(signInUrlBadge)},${escapeAndQuote(scanQrCode)},${escapeAndQuote(categoryCodes)}\n`;
            });

            const fileType = 'text/csv';

            let blob = new Blob([output], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            let fileNamePrefix = this.showDetails.Code;

            let fileNameCategories = this.categoryFilters.length > 0
                ? this.categoryFilters.join('_')
                : 'all';

            link.download = `${fileNamePrefix}_${fileNameCategories}.csv`;

            link.click();
            document.removeChild(link);            
        },

        async onDownloadRequest() {
            let book = new ExcelJS.Workbook();
            let sheet = book.addWorksheet('Attendees');

            let toWrite = this.filteredList;

            let categories = this.categoryFilters.length == 0
                ? this.showDetails.AttendeeCategories
                : this.showDetails.AttendeeCategories.filter(x => this.categoryFilters.indexOf(x.Code) != -1);

            let rows = [];

            let columns = [
                'Code',
                'First Name',
                'Last Name',
                'Display Name',
                'E-Mail',
                'Title',
                'Subtitle',
                'Pin',
                'ShowCode',
                'SignInUrl'
            ];

            for(let j = 0; j < categories.length; j++) {
                columns.push(
                    categories[j].Name
                );
            };

            sheet.addRow(columns);

            for(let i = 0; i < toWrite.length; i++) {
                let attendee = toWrite[i];

                let rowValues = [];

                rowValues.push(attendee.Code);
                rowValues.push(attendee.FirstName);
                rowValues.push(attendee.LastName);
                rowValues.push(attendee.DisplayName);
                rowValues.push(attendee.PrimaryEmail);
                rowValues.push(attendee.ContactInfo.Title);
                rowValues.push(attendee.ContactInfo.SubTitle);
                rowValues.push(attendee.SignInPin);
                rowValues.push(this.showDetails.Code);

                let link = `${getAttendeeAppUrl()}/login?l=${btoa(`${attendee.Code}:${attendee.SignInPin}:${this.showDetails.Code}`)}`;
                rowValues.push(link);

                for(let j = 0; j < categories.length; j++) {
                    let isInCategory = attendee.Categories.find(x => x.Code == categories[j].Code)
                        ? 'Yes'
                        : '';

                    rowValues.push(isInCategory);
                }

                sheet.addRow(rowValues);
            }

            sheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength + 5;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });

            for(let i = 0; i < columns.length; i++) {
                sheet.getCell(`${String.fromCharCode('A'.charCodeAt() + i)}1`).font = {
                    bold: true
                };
            }

            sheet.autoFilter = {
                from: 'A1',
                to: `${String.fromCharCode('A'.charCodeAt() + columns.length - 1)}1`
            };

            console.log(sheet.autoFilter);

            let buffer = await book.xlsx.writeBuffer();

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            let blob = new Blob([buffer], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            let fileNamePrefix = this.showDetails.Code;

            let fileNameCategories = this.categoryFilters.length > 0
                ? this.categoryFilters.join('_')
                : 'all';

            link.download = `${fileNamePrefix}_${fileNameCategories}.xlsx`;

            link.click();
            document.removeChild(link);

        },

        selectElementContents(el) {
            var body = document.body, range, sel;
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(el);
                range.select();
            }
        },

        onSaveAttendee(attendee) {
            let toUpdate = this.selectedElement;
            Object.assign(toUpdate, attendee);

            this.selectAttendee(null);

            this.onUpdateDetail(toUpdate);
        },

        startUploadCsv() {
            this.csvImportModal = new Modal(this.$refs.flashCsvDialog);

            this.$refs.csvFile.click();
        },

        fileChange(fieldName, fileList) {
            
            var that = this;
            this.columnsToMap = [];

            if(fileList && fileList.length > 0) {
                Papa.parse(fileList[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: function(results, file) {
                        that.categoriesToSet = [];

                        for(var i = 0; i < that.showDetails.AttendeeCategories.length; i++) {
                            that.categoriesToSet.push({
                                Selected: false,
                                Name: that.showDetails.AttendeeCategories[i].Name,
                                Code: that.showDetails.AttendeeCategories[i].Code
                            });
                        }

                        console.log(results.data);
                        that.toImport = results.data;
                        for(var i = 0; i < results.meta.fields.length; i++) {
                            that.columnsToMap.push({
                                ColumnName: results.meta.fields[i],
                                TypeCode: null
                            });
                        }

                        for(var i = 0; i < that.categoriesToSet.length; i++) {
                            that.categoriesToSet[i].Selected = false;
                        }

                        that.csvImportModal.show();
                    }
                });
            }
        },

        onUpdateDetail(attendee) {
            if(this.toUpdate.indexOf(attendee) == -1) {
                this.toUpdate.push(attendee);
            }
        },

        addAttendee() {
            this.page = 0;
            this.validateErrors = [];

            let newAttendee = new Attendee();
            newAttendee.ContactInfo = new AttendeeAdditionalInfo();
            newAttendee.Id = "0";
            newAttendee.Code = "";
            newAttendee.Show = {
                Code: this.showDetails.Code
            };

            if(this.categoryFilters.length > 0) {
                newAttendee.Categories = this.categoryFilters.map(x => {return {Code: x}; });
            }

            this.attendees.unshift(newAttendee);
            this.toUpdate.unshift(newAttendee);

            this.selectedElement = newAttendee;
        },
        validateForm: function() {
            this.validateErrors = [];

            for(var i = 0; i < this.toUpdate.length; i++){
                var editAttendee  = this.toUpdate[i];

                if(!editAttendee.RoleCode) {
                    let errorMsg = (editAttendee.Code == "") ? "the newly added attendee" : `attendee code '${editAttendee.Code}'`;
                    this.validateErrors.push(`Attendee Role not selected for ${errorMsg}.`);
                }
            }
            
            return this.validateErrors.length === 0;
        },

       async onSave(templateCode) {
            if(!this.validateForm()) {
                return;
            }

            this.submitting = true;
            try{
                let route = templateCode
                    ? `/api/attendeebatch?t=${templateCode}`
                    : '/api/attendeebatch'

                await this.tryPost(route, JSON.stringify(this.toUpdate), 'application/json');

                this.toUpdate = [];
                await this.build();

            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },

        async build() {
            this.submitting = true;

            try {   
                let result = await this.tryGet(`/api/show/${this.showCode}/attendees`);
                

                this.attendees = result.Result;
            } catch {
                alert("Could not load attendees. Try again later.");
            }

            this.submitting = false;

            Vue.nextTick(() => {
                this.$refs.attendeeList.resetFilters();
            })
        },

        flashToCsv() {
            for(var i = 0; i < this.toImport.length; i++) {
                var attendeeRow = this.toImport[i];

                var attendee = new Attendee();
                attendee.Code = '';
                attendee.ContactInfo = new AttendeeAdditionalInfo();
                attendee.AdditionalInfo = { Done: false };
                attendee.RoleCode = 'Attendee';
                attendee.Id = 0;
                attendee.Show = {
                    Code: this.showDetails.Code
                };
                
                for(var j = 0; j < this.columnsToMap.length; j++) {
                    var columnMap = this.columnsToMap[j];
                    var value = attendeeRow[columnMap.ColumnName];

                    if(!value || value.length == 0) {
                        continue;
                    }

                    value = value.trim();

                    if(columnMap.TypeCode == "Name" && value) {
                        var splitArray = value.split(" ");
                        if(splitArray.length == 0) {
                            continue;
                        }

                        attendee.FirstName = splitArray[0].toTitle();

                        if(splitArray.length > 1) {
                            attendee.LastName = splitArray[splitArray.length - 1].toTitle();
                        } else {
                            attendee.LastName = '';
                        }

                        attendee.DisplayName = attendee.FirstName + ' ' + attendee.LastName;
                    } else if(columnMap.TypeCode == "FirstName") {
                        attendee.FirstName = value.toTitle();
                    } else if(columnMap.TypeCode == "LastName") {
                        attendee.LastName = value.toTitle();
                    } else if(columnMap.TypeCode == "DisplayName") {
                        attendee.DisplayName = value.toTitle();
                    } else if(columnMap.TypeCode == "Code") {
                        attendee.Code = value.toUpperCase();                        
                    } else if(columnMap.TypeCode == "Pin") {
                        attendee.SignInPin = value;                        
                    } else if(columnMap.TypeCode == "Email") {
                        attendee.PrimaryEmail = value;
                    } else if(columnMap.TypeCode == "Title") {
                        attendee.ContactInfo.Title = value;
                    } else if(columnMap.TypeCode == "SubTitle") {
                        attendee.ContactInfo.SubTitle = value;
                    } else if(columnMap.TypeCode == "Notes") {
                        attendee.ContactInfo.Bio = value;
                    }
                }
                
                if(!attendee.DisplayName 
                    && attendee.FirstName
                    && attendee.LastName) {
                    
                    attendee.DisplayName = `${attendee.FirstName.trim()} ${attendee.LastName.trim()}`;
                } else if(!attendee.DisplayName) {
                    attendee.DisplayName = '';
                }

                if(!attendee.SignInPin) {
                    var total = '';
                    for(var j=0;j<4;j++){
                        var rand = Math.floor(Math.random()*10);
                
                        total = total + rand;
                    }
                    attendee.SignInPin = total;
                }

                for(var j = 0; j < this.categoriesToSet.length; j++) {
                    if(!this.categoriesToSet[j].Selected) {
                        continue;
                    }
                    attendee.Categories.push(this.categoriesToSet[j]);
                }

                this.attendees.push(attendee);
                this.toUpdate.push(attendee);
            }

            this.csvImportModal.hide();
        },
    },

    data() {
        return {
            validateErrors: [],
            toUpdate: [],
            mode: 'Copyable',

            roleTypes:[
                {Type:"Attendee",Name:"Attendee"},
                {Type:"Administrator", Name: "Administrator"},
            ],

            columnTypes: [
                { Name: '(Not Mapped)', Code: null },
                { Name: 'First Name', Code: 'FirstName' },
                { Name: 'Last Name', Code: 'LastName' },
                { Name: 'Display Name', Code: 'DisplayName' },
                { Name: 'E-Mail', Code: 'Email' },
                { Name: 'Title', Code: 'Title' },
                { Name: 'SubTitle', Code: 'SubTitle' },
                { Name: 'PIN', Code: 'Pin' },
                { Name: 'Notes', Code: 'Notes' },
                { Name: 'Code', Code: 'Code' }
            ],

            columnsToMap: [],
            categoriesToSet: [],
            toImport: [],
            selectedElement: null,
            attendees: [],

            csvImportModal: null,
            templateModal: null,

            templates: [],
            selectedTemplateCode: null,

            customColumns: [
                { 
                    Type: 'Button', 
                    Header: '', 
                    Icon: 'trash', 
                    EventName: 'delete',
                    Color: 'danger',
                    AlwaysShow: true
                }
            ]

        }
    },

    async created(){
        await this.getTractusLinkDestinationsAsync(this.showCode);
    },

    mounted() {
        this.build();
    }
}
</script>