<template>
    <loading v-if="submitting">
    </loading>

    <div v-else>
        <nav-bar></nav-bar>
        <vue-title :title="'All Short URLs'"></vue-title>
        <div class="container-fluid">
            
            <div class="table-responsive">
                <table class="table table-sm table-striped short-url-list">
                    <thead>
                        <tr>
                            <th>
                                Show
                            </th>
                            <th>
                                URL Code
                            </th>
                            <th>
                                Short URL
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Destination
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="5">
                                <button class="btn btn-outline-primary btn-sm"
                                        @click="addCandidateRow">
                                    Add New...
                                </button>
                            </td>
                        </tr>
                        <template v-for="item in shortUrlList">
                            <tr v-if="item._IsNew">
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.ShowCode"
                                               placeholder="Show Code">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.UrlCode"
                                               placeholder="URL Code">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               readonly
                                               :value="`u.tractus.ca/${item.ShowCode || ''}/${item.UrlCode || ''}`">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.Description"
                                               placeholder="Description">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.TargetUrl"
                                               placeholder="Target URL">
                                        <button class="btn btn-success btn-sm"
                                            @click="saveChangesToLink(item)">
                                            <i class="bi bi-floppy"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-else>
                                <td>
                                    {{ item.ShowCode }} {{ item._IsNew }}
                                </td>
                                <td>
                                    {{ item.UrlCode }}
                                </td>                            
                                <td>
                                    <div class="input-group">
                                        <button class="btn btn-primary btn-sm"
                                                @click="copyShortUrlToClipboard(item)">
                                            <i class="bi bi-share-fill"></i>
                                        </button>

                                        <a :href="`https://u.tractus.ca/${item.ShowCode}/${item.UrlCode}`" 
                                            class="btn btn-outline-primary btn-sm"
                                            target="_blank">
                                            <i class="bi bi-upload"></i>
                                        </a>

                                        <input readonly
                                            :value="`u.tractus.ca/${item.ShowCode}/${item.UrlCode}`"
                                            class="form-control form-control-sm"
                                            @click="copyShortUrlToClipboard(item)">


                                    </div>
                                </td>
                                <td>
                                    {{ item.Description }}
                                </td>
                                <td>
                                    <div class="input-group">
                                        <button class="btn btn-outline-primary btn-sm"
                                                @click="copyLongUrlToClipboard(item)">
                                            <i class="bi bi-share-fill"></i>
                                        </button>

                                        <a :href="item.TargetUrl" 
                                            class="btn btn-outline-primary btn-sm"
                                            target="_blank">
                                            <i class="bi bi-upload"></i>
                                        </a>

                                        <input v-model="item.TargetUrl"
                                            class="form-control form-control-sm">

                                        <button class="btn btn-success btn-sm"
                                                @click="saveChangesToLink(item)">
                                            <i class="bi bi-floppy"></i>
                                        </button>

                                    </div>
                                </td> 
                            </tr>
                        </template>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { 
    getApiUrl
} from './environment.hci'
import Toastify from 'toastify-js'

export default {
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            shortUrlList: [],
        }
    },

    methods: {
        addCandidateRow() {
            this.shortUrlList.splice(0, 0, {
                ShowCode: '',
                UrlCode: '',
                Description: '',
                _IsNew: true,
                TargetUrl: '',
            });
        },

        async saveChangesToLink(item) {
            this.submitting = true;
            try {
                await this.tryPost('/api/shorturl', JSON.stringify([item]), 'application/json');
                item._IsNew = false;
                Toastify({
                    text: "Saved.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();       
                
                this.submitting = false;
            } catch(ex) {
                Toastify({
                    text: "Update failed. See console for details.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();            

                this.build();
            }
        },

        copyShortUrlToClipboard(item) {
            let shortUrl = `https://u.tractus.ca/${item.ShowCode}/${item.UrlCode}`;
            navigator.clipboard.writeText(shortUrl);
            Toastify({
                text: "Copied link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();            
        },

        copyLongUrlToClipboard(item) {
            let shortUrl = item.TargetUrl;
            navigator.clipboard.writeText(shortUrl);
            Toastify({
                text: "Copied destination link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();            
        },

        async build() {
            this.submitting = true;
            this.errors = [];

            try {
                let result = await this.tryGet('/api/shorturls');
                
                this.shortUrlList = result.Result;
            } catch(ex) {
                this.errors = [ex];
                this.domains = [];
            }

            this.submitting = false;
        }
    },

    created() {
        this.build();
    },
}
</script>