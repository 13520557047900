<template>
    <div v-if="submitting">
        <loading></loading>
    </div>
    <div v-else
         class="w-100">
        <nav class="mb-3 mt-2 d-flex align-items-center space-between">
            <span class="flex-fill fs-5 ps-1">
                Video List
            </span>

            <div class="btn-group">
                <button class="btn btn-outline-primary"
                        @click="createRow">
                    New...
                </button>
                <button class="btn btn-outline-primary"
                        @click="build">
                    Refresh
                </button>
                <button class="btn btn-outline-success"
                        @click="saveChanges()">
                    Save Changes
                </button>
            </div>
        </nav>

        <table class="table table-hover table-responsive">
            <thead>
                <tr>
                    <th>
                        Room Name
                    </th>
                    <th>
                        Video Title
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Last Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="video in videos">
                    <tr :key="video.Code" @click="selectedRow = video">
                        <td class="col-md-3">
                            {{video.RoomCode == 'MANUAL' ? '(Manually Added)' : video.RoomName}}
                        </td>
                        <td class="col-md-3">
                            {{video.Name || "Untitled"}}
                        </td>
                        <td class="col-md-2">
                            {{video.ConversionStatusCode}}
                        </td>
                        <td class="col-md-2">
                            {{video.LastUpdated | moment("L LT")}}
                        </td>
                    </tr>
                    <tr v-if="selectedRow && selectedRow == video">
                        <td colspan="3">
                            <div class="row">
                                <div class="col">

                                    <div class="form-floating">
                                        <input v-model="video.DownloadedRawVideoUrl"
                                            placeholder="URL"
                                            class="form-control">
                                        <label>Downloaded Video URL</label>
                                    </div>

                                    <div class="ratio ratio-16x9 mt-3 border rounded overflow-hidden mx-auto"
                                        style="max-width: 400px; max-height: 300px">
                                        <video :src="video.DownloadedRawVideoUrl"
                                            controls
                                            preload="none">

                                        </video>
                                    </div>

                                </div>

                                <div class="col">

                                    <div class="form-floating">
                                        <input v-model="video.TractusM3U8Url"
                                            placeholder="URL"
                                            class="form-control">
                                        <label>Tractus M3U8 URL</label>
                                    </div>

                                    <div class="ratio ratio-16x9 mt-3 border rounded overflow-hidden mx-auto"
                                        v-if="video.TractusM3U8Url"
                                        style="max-width: 400px; max-height: 300px">


                                        <video-player :src="video.TractusM3U8Url">

                                        </video-player>
                                    </div>

                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-floating">
                                <input v-model="video.ExternalVideoUrl"
                                       placeholder="External Video URL"
                                       class="form-control">
                                <label>External Video URL</label>
                            </div>
                            <div class="form-floating">
                                <input v-model="video.Name"
                                       placeholder="Video Title"
                                       class="form-control">
                                <label>Video Title</label>
                            </div>

                            <div class="form-floating mt-1">
                                <textarea v-model="video.Description"
                                          placeholder="Description"
                                          class="form-control form-control-sm"
                                          style="height: 120px">
                                </textarea>
                                <label>Description</label>
                            </div>

                            <div class="form-floating mt-1">
                                <input v-model="video.ExternalVideoRoomId"
                                       placeholder="External Video ID"
                                       class="form-control">
                                <label>External Video ID</label>
                            </div>

                            <div class="form-floating mt-1">
                                <input v-model="video.RoomCode"
                                       placeholder="Room Code"
                                       disabled
                                       class="form-control">
                                <label>Tractus Room Code</label>
                            </div>

                            <div class="form-floating mt-1">
                                <input v-model="video.Code"
                                       placeholder="Code"
                                       disabled
                                       class="form-control">
                                <label>Tractus Video Code</label>
                            </div>
                            <div class="form-floating mt-1">
                                <input v-model="video.ConvertJobId"
                                       placeholder="Code"
                                       disabled
                                       class="form-control">
                                <label>Conversion Task ID</label>
                            </div>

                            <input type="file"
                                   ref="fileUploader"
                                   style="display: none"
                                   @change="onUploadSelected">

                            <div class="mt-1">
                                <div class="btn-group btn-group-sm">
                                    <button type="button"
                                            class="btn btn-outline-success"
                                            @click="saveChanges(video)">
                                        Save
                                    </button>
                                    <button type="button"
                                            class="btn btn-outline-primary"
                                            @click="requestUploadVideo()"
                                            v-if="!uploading">
                                        Upload
                                    </button>
                                    <button type="button"
                                            disabled
                                            class="btn btn-outline-secondary"
                                            v-else>
                                        Uploading...
                                    </button>
 
                                    <button type="button"
                                            class="btn btn-outline-success"
                                            @click="selectedRow = null">
                                        Cancel
                                    </button>
                                    <button type="button"
                                            class="btn btn-outline-danger"
                                            @click="deleteVideo(video)">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { BigNumber } from "bignumber.js";
import { BlobServiceClient, ContainerClient  } from '@azure/storage-blob'

const adjustedEpoch = new BigNumber("621355968000000000");

const dateTimeMax = new BigNumber("3155378975999999999");

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        async onUploadSelected() {
            let url = '/api/assets/video/sas';
            let result = await this.tryGet(url);

            let sasDetails = result.Result;
            console.log(sasDetails);

            const blobServiceClient = new BlobServiceClient(sasDetails.SASUri);

            const containerClient = new ContainerClient(sasDetails.SASUri);

            this.uploading = this.selectedRow;

            const promises = [];

            const file = this.$refs.fileUploader[0].files[0];

            const blobClient = containerClient.getBlockBlobClient(`${this.showCode}/${this.selectedRow.RoomCode}_${this.selectedRow.Code}/${file.name}`)
            promises.push(blobClient.uploadBrowserData(file));

            let finalPath = `${sasDetails.ContainerUri}/${blobClient.name}`;

            await Promise.all(promises);

            this.uploading = null;
            this.selectedRow.ExternalVideoUrl = finalPath;
            this.selectedRow.DownloadedRawVideoUrl = finalPath;
        },


        requestUploadVideo() {
            this.$refs.fileUploader[0].click();
        },

        async build() {
            this.submitting = true;
            
            try {

                let url = `/api/videos/${this.showCode}`;
                let result = await this.tryGet(url);

                this.videos = result.Result.sort((a, b) => a.RoomName > b.RoomName
                    ? 1 : a.RoomName < b.RoomName ? -1 : 0);                

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },        

        abortConversion(video) {

        },

        deleteVideo(video) {

        },
        
        createRow() {
            let toPush = {
                ShowCode: this.showCode,
                RoomCode: 'MANUAL',
                ExternalVideoUrl: '',
                TractusM3U8Url: '',
                DownloadedRawVideoUrl: '',
                ConversionStatusCode: 'NotStarted',
                Name: 'New Video',
                Description: '',
                CoverUrl: '',
                Code: this.getAzureCompatibleTimeStamp(),
                LastUpdated: new Date()
            };

            this.videos.splice(0, 0, toPush);
            this.selectedRow = toPush;
        },

        async saveChanges(video) {
            this.submitting = true;

            let toSave = this.videos;

            if(video) {
                toSave = [ video ];
            } 

            let url = `/api/videos/${this.showCode}`;
            var result = await this.tryPost(
                    url,
                    JSON.stringify(toSave), 
                    'application/json');

            this.build();
        },

        getAzureCompatibleTimeStamp() {
            // IMPORTANT: We use BigInt here because the Azure ticks are > 15 digits, and JS numbers
            // can't handle that.

            // new Date() is always guaranteed to be UTC.
            // let compareDate = new Date();
            // const adjustedEpoch = 621355968000000000n;
            // const dateTimeMax = 3155378975999999999n;
            // let compareTicks = BigInt(10000 * compareDate.getTime()) + adjustedEpoch;
            // let stamp = (dateTimeMax - compareTicks).toString().padStart(20, '0');

            let compareDate = new Date();

            let compareTicks = dateTimeMax.minus(new BigNumber(compareDate.getTime()).times(10000).plus(adjustedEpoch));

            let stamp = compareTicks.toString().padStart(20, '0');

            return stamp;
        },        
    },

    data(){ 
        return{           
            videos: [],
            selectedRow: null,
            uploading: null,
        }
    },

    async created(){
        await this.build();
    }
}
</script>