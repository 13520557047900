<template>
    <div>
        <div class="row mt-2">
            <div class="col-auto">
                <slot name="mainbuttons">

                </slot>
            </div>
            <div class="col-auto">
                <div class="d-flex align-items-center">
                    <select class="form-control form-control"
                            v-model="limit">
                        <option v-for="i in limits" :value="i">
                            {{i > 1000 ? 'All' : i}} items
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex w-100 h-100 justify-content-center align-items-center">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link user-select-none pe-auto"
                                @click="goPage(-1)">
                                <i class="bi bi-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item">
                            <select class="form-control" v-model="page">
                                <option v-for="i in totalPages"
                                        :value="i - 1">
                                    {{i}}
                                </option>
                            </select>
                        </li>
                        <li class="page-item">
                            <a class="page-link user-select-none pe-auto"
                                @click="goPage(1)">
                                <i class="bi bi-chevron-right"></i>
                            </a>
                        </li>
                        <li class="page-item d-flex align-items-center">
                            <div class="me-2 ms-2">
                                {{Math.min(page * limit, attendees.length) + 1}} - {{Math.min((page + 1) * limit, attendees.length)}} ({{filteredList.length}} / {{attendees.length}})
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-auto">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="categoryFilter"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown">
                        <i class="bi bi-filter"
                           v-if="categoryFilters.length == 0"></i>
                        <i class="bi bi-filter-square-fill"
                           v-if="categoryFilters.length > 0"></i>
                    </button>

                    <ul class="dropdown-menu">
                        <li v-for="category in showDetails.AttendeeCategories"
                            :key="category.Code"
                            @click="setCategoryFilter(category.Code)"
                            :class="[categoryFilters.indexOf(category.Code) != -1 ? 'active' : '']"
                            class="dropdown-item user-select-none pe-auto">
                            {{category.Name}}
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item user-select-none pe-auto"
                            @click="categoryFilters = []">
                            Clear Include Filter
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-auto">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="categoryFilter2"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown">
                        <i class="bi bi-funnel"
                           v-if="categoryFiltersExclude.length == 0"></i>
                        <i class="bi bi-funnel-fill"
                           v-if="categoryFiltersExclude.length > 0"></i>
                    </button>

                    <ul class="dropdown-menu">
                        <li v-for="category in showDetails.AttendeeCategories"
                            :key="category.Code"
                            @click="setCategoryFilterExclude(category.Code)"
                            :class="[categoryFiltersExclude.indexOf(category.Code) != -1 ? 'active' : '']"
                            class="dropdown-item user-select-none pe-auto">
                            {{category.Name}}
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item user-select-none pe-auto"
                            @click="categoryFiltersExclude = []">
                            Clear Exclude Filter
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col">
                <input v-debounce:150="updateSearchTerm"
                        placeholder="🔎"
                        class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-hover table-striped table-sm" ref="attendeeList">
                    <thead>
                        <tr class="atn-header border-bottom border-dark bg-body-tertiary">
                            <th>Code</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th v-if="!showSidebar">Role</th>
                            <th v-if="!showSidebar">
                                Primary Email
                            </th>
                            <th v-if="!showSidebar">
                                Title
                            </th>
                            <th v-if="!showSidebar">
                                SubTitle
                            </th>
                            <th v-if="!showSidebar && showSignInLink">
                                PIN
                            </th>
                            <th v-if="!showSidebar && showSignInLink">
                                
                            </th>
                            <template v-if="customColumns">
                                <th v-for="column in customColumns"
                                    v-show="!showSidebar || column.AlwaysShow"
                                    :key="column.Path">
                                    {{column.Header}}
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(attendee, idx) in listItems"
                            @click="selectAttendee(attendee)"
                            :class="[(selectedElement && selectedElement == attendee) || (selectedCodes && selectedCodes.indexOf(attendee.Code) != -1) ? 'table-primary' : '']">
                            <td>{{attendee.Code || '(NEW)'}}</td>
                            <td>
                                {{attendee.FirstName}}
                            </td>
                            <td>
                                {{attendee.LastName}}
                            </td>
                            <td v-if="!showSidebar">
                                <!-- {{attendee.RoleCode}} -->
                                {{attendee.RoleCode == null ?  'Attendee' : attendee.RoleCode}}
                            </td>
                            <td v-if="!showSidebar"
                                @contextmenu="copyAttendeeEmail($event, attendee)">
                                <button class="btn btn-secondary btn-sm"
                                            @click.stop="copyAttendeeEmail($event, attendee)">
                                    <i class="bi bi-clipboard"></i>
                                </button>

                                {{attendee.PrimaryEmail}}
                            </td>
                            <td v-if="!showSidebar">
                                {{attendee.ContactInfo.Title}}
                            </td>
                            <td v-if="!showSidebar">
                                {{attendee.ContactInfo.SubTitle}}
                            </td>
                            <td v-if="!showSidebar && showSignInLink">
                                {{attendee.SignInPin}}
                            </td>
                            <td v-if="!showSidebar && showSignInLink">
                                <div class="btn-group">
                                    <a :href="`${getLoginForAttendee(attendee)}`"
                                        target="_blank"
                                        class="btn btn-primary"
                                        v-if="attendee.Code"
                                        @click.stop>
                                        <i class="bi bi-box-arrow-up-right"></i>
                                    </a>
                                    <button class="btn btn-secondary"
                                            @click.stop="copyAttendeeLink(attendee)">
                                        <i class="bi bi-clipboard"></i>
                                    </button>
                                </div>
                            </td>
                            <template v-if="customColumns">
                                <td v-for="(column, idx2) in customColumns"
                                    v-show="!showSidebar || column.AlwaysShow"
                                    :key="column.Path + idx + idx2">
                                    <input v-if="column.Type == 'Checkbox' && column.Path == 'AllowEditEntity'"
                                           v-model="attendee[column.Path]"
                                           :disabled="column.ReadOnly"
                                           @change="attendeeEdited(attendee)"
                                           type="checkbox"
                                           class="form-check-input"
                                           @click.stop>
                                    <input v-else-if="column.Type == 'Checkbox' && column.Path == 'IsReadOnly'"
                                           v-model="attendee[column.Path]"
                                           :disabled="column.ReadOnly"
                                           @change="attendeeEdited(attendee)"
                                           type="checkbox"
                                           class="form-check-input"
                                           @click.stop>
                                    <input v-else-if="column.Type == 'Checkbox' && column.Path == 'ReceiveAdminEmail'"
                                           v-model="attendee[column.Path]"
                                           :disabled="column.ReadOnly"
                                           @change="attendeeEdited(attendee)"
                                           type="checkbox"
                                           class="form-check-input"
                                           @click.stop>
                                    <button v-else-if="column.Type == 'Button'"
                                            @click.stop="emitButtonEvent(column.EventName, attendee)"
                                            type="button"
                                            :class="`btn btn-${column.Color}`">
                                        <i v-if="column.Icon"
                                           :class="`bi bi-${column.Icon}`"></i>
                                        <span v-if="column.Label">
                                            {{column.Label}}
                                        </span>
                                    </button>
                                    <span v-else>
                                        {{attendee[column.Path]}}
                                    </span>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            <div class="col-md-8" v-if="showSidebar">
                <slot name="sidebar">

                </slot>
            </div>
           
        </div>
    </div>
</template>
<script>
import Toastify from 'toastify-js'

import { getAttendeeAppUrl } from './environment.hci'

export default {
    props: [
        'showSidebar',
        'showSignInLink',
        'showDetails',
        'attendees',
        'showCode',
        'selectedElement',
        'selectedCodes',
        'customColumns',
        'disableSelection'
    ],

    watch: {
        limit() {
            this.page = 0;
        }
    },

    computed: {
        getAttendeeAppUrl() {
            return getAttendeeAppUrl();
        },

        filteredList() {
            let items = this.attendees;

            if(this.categoryFilters.length > 0) {
                items = items.filter(x => {
                    if(!x.Categories || x.Categories.length == 0) {
                        return false;
                    }                
                    
                    let match = false;
                    for(const filter of this.categoryFilters) {
                        if(x.Categories.find(y => y.Code == filter)) {
                            match = true;
                        } 
                    }
                    
                    return match;
                });
            }

            if(this.categoryFiltersExclude.length > 0) {
                items = items.filter(x => {
                    if(!x.Categories || x.Categories.length == 0) {
                        return false;
                    }                
                    
                    for(const filter of this.categoryFiltersExclude) {
                        
                        if(x.Categories.find(y => y.Code == filter)) {
                            return false;
                        }
                    }
                    
                    return true;
                });
            }

            if(this.searchText && this.searchText.length > 0) {
                let searchTest = new RegExp(this.searchText, 'i');

                items = items.filter(s => 
                    searchTest.test(s.DisplayName) 
                    || searchTest.test(s.ContactInfo.Title)
                    || searchTest.test(s.ContactInfo.SubTitle)
                    || searchTest.test(s.Code)
                    || searchTest.test(s.PrimaryEmail));
            }

            return items;
        },

        listItems() {

            let items = this.filteredList;

            let skipAmount = this.page * this.limit;
            let takeAmount = this.limit;

            
            items = items.slice(skipAmount, skipAmount + takeAmount);

            return items;
        },

        totalPages() {
            return Math.ceil(this.attendees.length / this.limit);
        }        
    },

    methods: {
        copyAttendeeEmail(e, attendee) {
            e.preventDefault();
            navigator.clipboard.writeText(attendee.PrimaryEmail);

            Toastify({
                text: "Copied e-mail to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        getLoginForAttendee(attendee) {
            let link = `${getAttendeeAppUrl()}/login?l=${btoa(`${attendee.Code}:${attendee.SignInPin}:${this.showCode}`)}`;
            return link;
        },

        copyAttendeeLink(attendee) {
            let link = this.getLoginForAttendee(attendee);

            navigator.clipboard.writeText(link);

            Toastify({
                text: "Copied sign-in to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        emitButtonEvent(eventName, attendee) {
            this.$emit('custom-event', eventName, attendee);
        },

        resetFilters() {
            this.searchText = '';
            this.page = 0;
        },

        attendeeEdited(attendee) {
            this.$emit('attendee-edited', attendee);
        },

        selectAttendee(attendee) {
            if(this.disableSelection) {
                return;
            }
            
            this.$emit('select-attendee', attendee);
        },

        setCategoryFilter(categoryCode) {
            
            if(!categoryCode) {
                this.categoryFilters = [];

            } else if(this.categoryFilters.length == 0) {
                this.categoryFilters.push(categoryCode);
            } else {

                let index = this.categoryFilters.indexOf(categoryCode);

                if(index != -1) {
                    this.categoryFilters.splice(index, 1);
                } else {
                    this.categoryFilters.push(categoryCode);
                }
            }

            console.log(this.categoryFilters);
        },


        setCategoryFilterExclude(categoryCode) {
            
            if(!categoryCode) {
                this.categoryFiltersExclude = [];

            } else if(this.categoryFiltersExclude.length == 0) {
                this.categoryFiltersExclude.push(categoryCode);
            } else {

                let index = this.categoryFiltersExclude.indexOf(categoryCode);

                if(index != -1) {
                    this.categoryFiltersExclude.splice(index, 1);
                } else {
                    this.categoryFiltersExclude.push(categoryCode);
                }
            }

            console.log(this.categoryFiltersExclude);
        },        

        goPage(direction) {
            console.log('Direction, page, total:', direction, this.page, this.totalPages);

            if(direction < 0 && this.page == 0) {
                this.page = this.totalPages - 1;
                return;
            }

            if(direction > 0 && this.page == this.totalPages - 1) {
                this.page = 0;
                return;
            }

            this.page += direction;
        },

        updateSearchTerm(inputVal) {
            this.searchText = inputVal;
        },        
    },

    data() {
        return {
            searchText: '',
            limit: 25,
            limits: [10, 25, 50, 100, 999999],
            page: 0,
            categoryFilters: [],
            categoryFiltersExclude: [],
        }
    }
}
</script>