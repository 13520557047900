<template>
    <loading v-if="submitting">

    </loading>
    <div v-else
         class="container-fluid">
        <vue-title :title="poll.Name"></vue-title>
        <h4 class="mt-2 mb-2 text-center">{{ poll.Name }} - Results ({{ poll.Responses.length }})</h4>
         <table class="table table-responsive table-hover table-striped tPollInviteListable-sm table-responsive response-table">
            <thead class="atn-header border-bottom border-dark bg-body-tertiary sticky-top">
                <tr>
                    <th v-for="question in tableQuestions">
                        {{ question.Name }}
                    </th>
                    <th>
                        Code
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="response in poll.Responses">
                    <tr>
                        <td v-for="question in tableQuestions">
                            <a v-if="question.QuestionType == 'ImageUpload'"
                                class="btn btn-outline-primary btn-sm"
                                @click.stop
                                :href="formatQuestionOutput(question.Code, response)"
                                target="_blank">
                                <i class="bi bi-download">

                                </i>
                            </a>
                            <span v-else>
                                {{ formatQuestionOutput(question.Code, response) }}
                            </span>
                        </td>
                        <td>
                            <a :href="pollLink(response)"
                               target="_blank">
                                {{ response.Id }}
                            </a>
                        </td>
                    </tr>
                </template>
            </tbody>

        </table>      
        
        <button type="button" 
                class="btn btn-success btn-lg btn-fixed-action"
                @click="downloadRegListToExcel">
            <i class="bi bi-cloud-download-fill"></i> Download
        </button>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { getPollAppUrl } from './environment.hci';
import ExcelJS from 'exceljs'
import moment from 'moment'

export default {
    props: ['pollCode', 'showCode', 'code'],
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            poll: null,
        }
    },

    computed: {
        tableQuestions() {
            return this.poll.Questions.filter(x => 
                x.QuestionType != 'TextPrompt'
                && x.QuestionType != 'GroupInsertButton'
                && !(x.GroupTag && !x.SubGroup));
        },        
    },

    methods: {
        async downloadRegListToExcel() {
            let book = new ExcelJS.Workbook();
            let sheet = book.addWorksheet('Responses');

            let columns = [
                'Code',
                'Submitted',
            ];

            let questions = this.tableQuestions;

            console.log(questions);

            for(let i = 0; i < questions.length; i++) {
                columns.push(questions[i].Name);
            }

            sheet.addRow(columns);

            let toWrite = this.poll.Responses;

            for(let i = 0; i < toWrite.length; i++) {
                let poll = toWrite[i];

                let rowValues = [];
                rowValues.push(poll.Id);
                rowValues.push(poll.SubmittedDateTime);

                for(let j = 0; j < questions.length; j++) {
                    let question = questions[j];

                    let found = poll.Questions.find(x => x.Code == question.Code);

                    let valueToWrite = '';

                    if(found) {

                        let response = found.Response;
                        if(found.QuestionType == "MultipleChoice") {
                            let answer = question.Answers.find(x => x.Response == found.Response);

                            if(answer) {
                                response = `${answer.DisplayText}`;
                            }
                        }

                        valueToWrite = response;
                    }
                    rowValues.push(valueToWrite);
                }

                sheet.addRow(rowValues);

            }

            sheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength + 5;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });

            for(let i = 0; i < columns.length; i++) {
                sheet.getCell(`${String.fromCharCode('A'.charCodeAt() + i)}1`).font = {
                    bold: true
                };
            }

            sheet.autoFilter = {
                from: 'A1',
                to: `${String.fromCharCode('A'.charCodeAt() + columns.length - 1)}1`
            };

            let buffer = await book.xlsx.writeBuffer();

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            let blob = new Blob([buffer], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            link.download = `Results_${moment().format('YYYY_MM_DD_HH_mm')}_${this.showCode}_${this.poll.Name}.xlsx`;

            link.click();
            document.removeChild(link);
        },

        async build() {
            this.submitting = true;

            this.poll = await this.tryGetAnonymous(`/api/pollresult/${this.showCode}/${this.pollCode}/${this.code}`)
            this.poll = this.poll.Result;

            this.submitting = false;
        },

        pollLink: function(poll) {
            return `${getPollAppUrl()}/#/pp/${this.poll.ShowCode}/${this.poll.Code}?r=${poll.Id}`;
        },


        formatQuestionOutput(code, response) {
            if(!response) {
                return 'NF';
            }
            
            let question = response.Questions.find(x => x.Code == code);

            if(!question || !question.Response) {
                return '';
            }

            if(this.isMultipleChoiceQuestion(question)) {
                let responseText = question.Response;   
                let response = question.Answers.find(x => x.Response == responseText);

                if(!response) {
                    return responseText;
                }

                return response.DisplayText;
            }

            return question.Response;
        },


        isMultipleChoiceQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'MultipleChoice'
                    || question.QuestionType == 'MultipleChoiceMultiSelect'
                    || question.QuestionType == 'MultipleChoiceDropdown');
        },        

        isQuestionGroupTemplate(question) {
            return question.GroupTag && !question.SubGroup;
        },

    },

    mounted() {
        this.submitting = true;
        this.build();
    }
}
</script>