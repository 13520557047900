<template>
    <div v-if="submitting">
        <loading></loading>
    </div>
    <div v-else-if="show"
         class="show-edit">
        <vue-title :title="show.Name"></vue-title>

        <nav class="sticky-top border-bottom bg-body-tertiary navbar">
            <div class="container-fluid">
                <router-link :to="{name: 'ShowList'}"
                             class="me-3">
                    <i class="bi bi-chevron-left"></i>
                    Back
                </router-link>
                <router-link :to="{name: 'ShowBasics'}" class="navbar-brand">
                    <span class="">{{show.Name}} ({{show.Code}})</span>
                </router-link>
                <div class="d-flex">
                    <button type="button" 
                            class="btn btn-primary" 
                            @click="handlePreviewClick">Preview</button>
                </div>
            </div>
        </nav>

        <div class="show-edit-container d-flex flex-nowrap">
            <div class="col-auto px-sm-2 pt-1 pb-1 px-0 sticky-top overflow-auto">
                <ul class="nav nav-pills flex-column mb-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'ShowEditDetails' }" active-class="active">
                            App Layout
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'ThemeEditor' }" active-class="active">
                            Branding
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/polls`}" active-class="active">
                            Polls
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'chats'}" active-class="active">
                            Chat Rooms
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/streams` }" active-class="active">
                            Live Streams
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/replays` }" active-class="active">
                            Replays
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/emailsetup` }" active-class="active">
                            E-Mail Setup
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/emails/templates` }" active-class="active">
                            E-Mail Templates
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/emails/campaigns` }" active-class="active">
                            E-Mail Campaigns
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'AppNotifications' }" active-class="active">
                            Announcements
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/attendeebatch`}" active-class="active">
                            Attendees
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/attendees/categories`}" active-class="active">
                            Attendee Categories
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ path: `/shows/${this.showCode}/registration`}" active-class="active">
                            Registration Forms
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'sessionLanding'}" active-class="active">
                            Sessions
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'IcalInvites'}" active-class="active">
                            Session Calendar Invites
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/pages/details`}" active-class="active">
                            Pages
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/bookableAdmin`}" active-class="active">
                            Page Chats
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/pages/categories`}" active-class="active">
                            Page Categories
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/favorites`}" active-class="active">
                            Favorites
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/zoom`}" active-class="active">
                            Zoom Setup
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/webhooks`}" active-class="active">
                            Outbound WebHooks
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/inboundwebhooks`}" active-class="active">
                            Inbound Webhooks
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{path: `/shows/${this.showCode}/videos`}" active-class="active">
                            Videos (Legacy)
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a @click="onChoose"  class="nav-link" style="cursor:pointer;" active-class="active">
                            File Upload
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col overflow-auto">
                <router-view :key="$route.path" 
                            :showDetails="show" 
                            :showCode="showCode"></router-view>
            </div>
        </div>
        
        <div>
            <file-picker ref="fileUploadMain" 
                                imagePath=""
                                :showCode="showCode"/>
            <div class="modal fade" 
                id="previewAttendee"
                ref="previewAttendee">
                <!-- modal-fullscreen -->
                <div class="modal-dialog ">
                    <div class="modal-content">
                        <div class="modal-header">
                            Select Preview Attendee
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body file-upload-modal-body">
                            <div class="container-fluid">
                                <loading v-if="loadingAttendeeList"></loading>
                                <select v-model="selectedPreviewAttendee" v-else>
                                    <option v-for="attendee in previewAttendeeList" :value="attendee">
                                        {{attendee.DisplayName}}
                                    </option>
                                </select>
                                
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" 
                                    class="btn btn-secondary" 
                                    data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button"
                                    class="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    @click="launchPreviewWindow(show)">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

        <div class="signalr-connection-overlay icomoon"
             :class="[`state-${signalRConnectMode}`]">
            
        </div>        
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import ShowPreview from './show.preview.base'
import Common from './common'
import loading from './loading.vue'
import { Modal } from 'bootstrap'

export default {
  components: { loading },
    props: ['showCode'],

    mixins: [
        Token,
        Common,
        ShowPreview
    ],   

    watch: {
        showCode(to, from) {
            if(to != from) {
                if(from) {
                    window.$signalr.unsubscribeFromShowAsync(from);
                }

                this.loadShow();
            }
        }
    },

    computed: {
        signalRConnectMode() {
            this.HACK_connectMode;
            return window.$signalr.signalRConnectMode;
        }
    },

    methods: {
        onSignalRConnectionStateChange() {
            this.HACK_connectMode++;
        },

        onUpdateShowPreview() {
            if(this.queueUpdate) {
                this.queueUpdate();
            }
        },

        handlePreviewClick(){
            if(this.show.IsPublicShow){
                this.launchPreviewWindow(this.show);
                return;
            }
            this.loadPreviewAttendees();
            
            if(!this.modal) {
                this.modal = new Modal(this.$refs.previewAttendee);
            }

            this.modal.show();
        },
        getShowPreviewAttendee(){
            this.selectedPreviewAttendee.Show = this.show;
            return this.selectedPreviewAttendee;
        },

        async loadPreviewAttendees(){
            if(this.previewAttendeeList && this.previewAttendeeList.length > 0){
                return;
            }
            this.loadingAttendeeList = true;
            // 
            try{
                let r = await this.tryGet(`/api/show/${this.showCode}/attendees`);
                
                if(r.Errors && r.Errors.length === 0){
                    this.previewAttendeeList = r.Result;
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.loadingAttendeeList = false;
        },
        onChoose(){
            // console.log(this.show);
            this.$refs.fileUploadMain.openPicker();
        },

        async loadShow() {
            this.submitting = true;
            try{
                let r = await this.tryGet('/api/show/' + this.showCode);
                
                if(r.Errors && r.Errors.length === 0){

                    if(r.Result.Sections) {
                        for(var i = 0; i < r.Result.Sections.length; i++) {
                            if(!r.Result.Sections[i].MaxItemsToDisplay) {
                                r.Result.Sections[i].MaxItemsToDisplay = 0;
                            }
                        }
                    }

                    this.show = r.Result;
                }

                await window.$signalr.initializeAsync(this.getUserDetails().token);
                window.$signalr.subscribeToShowAsync(this.showCode);
            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
        }
    },

    data() {
        return {
            show: null,
            editShow: null,

            editDialogTitle: '',
            modal: null,

            HACK_connectMode: 0
        }
    },

    beforeDestroy() {
        this.bus.$off("Do-Show-Reload", () => this.loadShow());
        this.bus.$off("Update-Show-Preview",() => this.onUpdateShowPreview())
        this.bus.$off('signalr-connection-state-change', this.onSignalRConnectionStateChange);
        window.$signalr.unsubscribeFromShowAsync(this.showCode);
    },

    created() {   
        this.bus.$on("Do-Show-Reload", () => this.loadShow());
        this.bus.$on("Update-Show-Preview",() => this.onUpdateShowPreview())
        this.bus.$on('signalr-connection-state-change', this.onSignalRConnectionStateChange);
        this.loadShow();
    },
}
</script>