<template>
    <div v-if="submitting">
        <loading></loading>
    </div>
    <div v-else
         class="container-fluid scrolling-columns">
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Replays
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>

                    </div>
                </div>

                <!--
                
                    public string ShowCode { get; set; }
    public string LiveStreamId { get; set; }
    public string Name { get; set; }
    public string StreamKey { get; set; }
    public string PlaybackKey { get; set; }
    public string NextAssociatedSessionCode { get; set; }
    public string Status { get; set; }
                
                -->
                <div class="row">
                    <div class="col-md-4 gy-2"
                         v-for="stream in replays"
                         :key="stream.VideoCode">
                        <div class="card">
                            <div v-if="selectedRow == stream && stream.PlaybackId && stream.Status == 'ready'" class="card-top">
                                <mux-player
                                    :playback-id="stream.PlaybackId"
                                    :metadata-video-id="`${showCode}_${stream.VideoCode}`"
                                    :metadata-video-title="stream.Name"
                                    metadata-viewer-user-id="AdminConsole"
                                />                                
                            </div>
                            <div class="card-body">                                
                                <h6 class="card-title" @click="selectedRow = stream">
                                    {{ stream.Name }}
                                </h6>
                                <h5 class="card-subtitle">
                                    {{ friendlyDuration(stream.DurationSec) }}<span v-if="stream.MasterStatus">, Master Access: <i>{{ stream.MasterStatus }}</i></span>
                                </h5>
                            </div>

                            <div class="input-group p-1">
                                <select class="form-select form-control-sm"
                                    v-model="stream.AssociatedSessionCode">
                                    <option :value="showCode">
                                        (No Session Selected)
                                    </option>
                                    <optgroup v-for="(category, key) in sessions"
                                            :key="key"
                                            :label="getSessionCategoryName(key)">
                                        <option v-for="session in category"
                                                :key="session.Code"
                                                :value="`${showCode}_${session.Code}`">
                                            {{ session.Name }}
                                        </option>
                                    </optgroup>
                                </select>                                
                            </div>
                            
                            <div class="input-group mt-2 p-1">

                                <button class="btn btn-outline-danger btn-sm"
                                        @click="clearAssignment(stream)">
                                    Clear
                                </button>
                                <button class="btn btn-outline-primary btn-sm"
                                        @click="assignToRoom(stream)">
                                    Assign
                                </button>
                                <button class="btn btn-outline-primary btn-sm"
                                        @click="askForMasterAccess(stream)">
                                    Enable/Refresh Master
                                </button>
                                <a v-if="stream.MasterStatus == 'ready' && stream.MasterUrl" :href="stream.MasterUrl" 
                                   download
                                   class="btn btn-sm btn-outline-success">
                                    Download
                                </a>                                
                            </div>

                            <div class="btn-group">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Session Mappings
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>
                    </div>                    
                </div>


                <table class="table table-responsive">
                    <tbody>
                        <template v-for="(category, key) in sessions">
                            <tr>
                                <th colspan="3">
                                    {{ getSessionCategoryName(key) }}
                                </th>
                            </tr>
                            <tr v-for="session in category" :key="session.Code">
                                <td>
                                    {{ session.Name }}<br>
                                    <i v-if="getAssociatedReplay(session)">🎥{{ getAssociatedReplay(session) }}</i>
                                </td>
                                <td width="150">
                                    {{ session.SessionStartDateTime | moment('YY-MM-DD HH:mm') }}
                                </td>
                                <td>
                                    <div v-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxLive'"
                                         class="badge text-bg-success">
                                        Mux Live
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxAsset'"
                                         class="badge text-bg-primary">
                                        Replay
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'Video' && session.PrimaryMedia.Uri"
                                         class="badge text-bg-warning">
                                        Video (M3U8)
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'LiveWebSource' && session.PrimaryMedia.Uri"
                                        class="badge text-bg-warning">
                                        Embed
                                    </div>
                                    <div v-else
                                         class="badge text-bg-secondary">
                                        (None)
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>
<script>
import "@mux/mux-player";

import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import ExcelJS from 'exceljs'
import Toastify from 'toastify-js'
import { offset } from "@popperjs/core";

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        friendlyDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        },

        getAssociatedReplay(session) {
            if(!session.PrimaryMedia 
                || session.PrimaryMedia.MediaType != 'MuxAsset'
                || !session.PrimaryMedia.Uri) {
                return "";
            }

            let replay = this.replays.find(x => x.PlaybackId == session.PrimaryMedia.Uri);

            if(!replay) {
                return "";
            }

            return replay.Name;
        },

        async askForMasterAccess(stream) {
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/enable-master`;
            var result = await this.tryPut(url);
            this.build();
        },

        async assignToRoom(stream) {
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/assign/${stream.AssociatedSessionCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        async clearAssignment(stream) {
            // api/mux/livestream/{showCode}/{streamCode}/assign/{sessionCode?}
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/assign/${this.showCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        getSessionCategoryName(categoryCode) {
            let category = this.showDetails.SessionCategories.find(x => x.Code == categoryCode);
            if(!category) {
                return categoryCode;
            }

            return category.Name;
        },

        async build() {
            this.submitting = true;
            
            try {

                let url = `/api/mux/assets/${this.showCode}`;
                let result = await this.tryGet(url);

                this.replays = result.Result.sort((a, b) => a.Name.localeCompare(b.Name));

                result = await this.tryGet(`/api/show/${this.showCode}/sessions`);
                this.sessions = result.Result;

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },        

        createRow() {
            /*
                
                    public string ShowCode { get; set; }
    public string LiveStreamId { get; set; }
    public string Name { get; set; }
    public string StreamKey { get; set; }
    public string PlaybackKey { get; set; }
    public string NextAssociatedSessionCode { get; set; }
    public string Status { get; set; }

            */
            let toPush = {
                ShowCode: this.showCode,
                LiveStreamId: '',
                Name: 'New Stream Endpoint',
                StreamKey: '',
                PlaybackKey: '',
                NextAssociatedSessionCode: this.showCode,
                Status: 'new',
            };

            this.streams.splice(0, 0, toPush);
            this.selectedRow = toPush;
        },

        async saveChanges(stream) {
            this.submitting = true;

            let toSave = stream;

            let url = `/api/mux/asset`;
            var result = await this.tryPost(
                    url,
                    JSON.stringify(toSave), 
                    'application/json');

            this.build();
        },

        async deleteStream(stream) {
            if(!confirm("Are you absolutely sure you want to delete this replay?")) {
                return;
            }

            if(!stream.LiveStreamId) {
                this.build();
                return;
            }

            this.submitting = true;
            let url = `/api/mux/asset/${this.showCode}/${stream.VideoCode}`;
            var result = await this.tryDelete(url);    
            
            this.build();
        },

        onControlChannelMessage(data) {

            console.warn(data);
            if(!data.TypeCode ) {
                return;
            }

            if(data.TypeCode == 'EntityUpdated') {
                this.build();
                return;
            }

            if(data.TypeCode == 'MuxVideoUpdated') {
                if(data.ShowCode != this.showCode) {
                    console.warn("Mismatch of show code. Return");
                    return;
                }

                this.build();
            }
        },
    },

    data(){ 
        return{           
            replays: [],
            selectedRow: null,
            sessions: [],
        }
    },

    async created(){
        await this.build();
        this.bus.$on('Control-Channel-Message', this.onControlChannelMessage);
    },

    beforeDestroy() {
        this.bus.$off('Control-Channel-Message', this.onControlChannelMessage);
    }
}
</script>