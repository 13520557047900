<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <button class="btn btn-primary" @click="onSave">
            Save
        </button>
        <table class="table table-responsive">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Page Chats<br><button class="btn btn-outline-primary btn-sm" type="button" @click="onSelectAllDM()">Select All</button></th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(category, categoryCode) in vendors">
                    <tr>
                        <th>
                            {{ getCategoryName(categoryCode) }} ({{ category.length }})
                        </th>
                        <th>
                            <button class="btn btn-outline-primary btn-sm" type="button" @click="onSelectAllDM(categoryCode)">Select All</button>
                        </th>
                    </tr>
                    <tr v-for="item in category" :key="item.Entity.Code">
                        <td>
                            {{ item.Entity.Name }}
                        </td>
                        <td>
                            <input type="checkbox"
                                       v-model="item.CanHavePageMessages"
                                       class="form-check-input">
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import Vendor from '../models/Vendor'
export default {
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    methods: {  

        onSelectAllDM(categoryCode) {
            if(!categoryCode) {
                let keys = Object.keys(this.vendors);
                keys.forEach(this.onSelectAllDM);
                return;
            }

            for(var i = 0; i < this.vendors[categoryCode].length; i++){
                this.vendors[categoryCode][i].CanHavePageMessages = this.vendors[categoryCode][i].CanHavePageMessages ? false : true;
            }
        },

        getCategoryName(code) {
            let category = this.showDetails.VendorCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        updateSelectedVendor(item) {
            this.selectedVendor = null;
            Vue.nextTick(() => this.selectedVendor = item);
        },

        async onSave() {
            try{

                var toUpdate = [];
                for(var vendorType in this.vendors){
                    for(var vendor of this.vendors[vendorType]){
                        toUpdate.push({
                            'EntityTypeCode': 'VENDOR',
                            'EntityCode': vendor.Entity.Code,
                            'CanHavePageMessages': vendor.CanHavePageMessages
                        });
                    }
                }
                
                await this.tryPost(`/api/appointment/admin/${this.showCode}/bookable`, JSON.stringify(toUpdate), 'application/json');
            }
            catch(ex){
                console.log(ex);
            }
        },

        onCancel() {
            this.selectedVendor = null;
        },

        async build() {
            this.submitting = true;
                       
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/vendors`);
                let vendors = result.Result;
                let entityTypeCode = 'VENDOR';
                let bookableResult = await this.tryGet(`/api/appointment/admin/${this.showCode}/bookable/${entityTypeCode}`);
                let bookableVendors = bookableResult.Result;

                for(var type in vendors){
                    let vendorsToAdd = []
                    for(var vendor of vendors[type]){
                        let isBookable = bookableVendors.find(x => x.EntityCode == vendor.Code);
                        vendorsToAdd.push({
                            'Entity': vendor,
                            'CanHavePageMessages': isBookable && isBookable.CanHavePageMessages
                        });
                    }
                    Vue.set(this.vendors,type,vendorsToAdd);
                }

                this.originalVendor = this.cloneObject(this.vendors);

            } catch(ex) {
                console.log(ex);
                alert("Could not load pages. Try again later.");
            }
            
            this.submitting = false;
        },
    },

    data() {
        return {
            vendors: {},
            selectedVendor: null,
            originalVendor: {},
        }
    },

    mounted: function() {
        this.build();
    },
}
</script>