<template>
    <loading v-if="submitting && !showDetails">
    </loading>
    <div class="container-fluid" v-else>
        <div class="row" v-if="validateErrors && validateErrors.length > 0">
            <div class="mt-1 alert alert-danger text-start" role="alert">
                    Oops! It looks like something happened. Please double-check and try again.
                    <ul>
                        <li v-for="error in validateErrors">
                            {{ error }}
                        </li>
                    </ul>
            </div>
        </div>
        <AttendeeListSelector :showSidebar="false"
                              :showSignInLink="false"
                              :showDetails="showDetails"
                              :attendees="sortedAttendees"
                              :showCode="showCode"
                              :selectedCodes="selectedAttendees"
                              @custom-event="onGridEvent"
                              @select-attendee="selectAttendee"
                              @attendee-edited="onAttendeeEdited"
                              v-if="!submitting"
                              :customColumns="customColumns"
                              :disableSelection="true"
                              ref="attendeeList">
            <template v-slot:mainbuttons>
                <div class="btn-group">
                    <select v-model="selectedEntity"
                            class="form-select">
                        <option :value="null">(Select Session...)</option>
                        <optgroup v-for="(entities, category) in sessions"
                                  :label="getLabelForCategoryCode(category)">
                            <option v-for="entity in entities"
                                    :value="entity">
                                {{entity.SessionStartDateTime | moment("L LT")}}: {{entity.Name}} 
                            </option>
                        </optgroup>
                    </select>
                    <button type="button" 
                            class="btn btn-primary buttonMargin"
                            @click="onSendInvites">
                        Send Invites
                    </button>
                </div>
            </template>
        </AttendeeListSelector> 
    </div>
</template>
<style scoped>
.buttonMargin {
    margin-right: 0.5em;
}
</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

import AttendeeListSelector from './attendee.list.selector.vue'

export default {
    props: ['showDetails', 'showCode'],

    components: { 'AttendeeListSelector': AttendeeListSelector },

    mixins: [
        Token,
        Common
    ],   

    watch:{
        selectedEntity(){
            if(this.selectedEntity){
                this.updatePageRoles();
            }
        }
    },

    computed: {
        sortedAttendees() {
            let toReturn = this.attendees;

            toReturn = toReturn.sort((a, b) => {
                let aI = this.selectedAttendees.indexOf(a.Code);
                let bI = this.selectedAttendees.indexOf(b.Code);

                if(aI == bI) {
                    return 0;
                } else if(aI == -1) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return toReturn;
        },

        categoryFilters() {
            return this.$refs.attendeeList.categoryFilters;
        },

        filteredList() {
            return this.$refs.attendeeList.filteredList;
        }
    },

    methods: {
        getLabelForCategoryCode(categoryCode) {
            let category = this.showDetails.SessionCategories.find(x=>x.Code == categoryCode);
            
            if(!category) {
                return '';
            }

            return category.Name;
        },

        async updatePageRoles(){
            try{
                this.selectedAttendees = [];

                let url = `/api/show/${this.showCode}/sessions/${this.selectedEntity.Code}/invites/history`;
                let r = await this.tryGet(url);

                let inviteHistory = r.Result;

                this.updateInviteHistory(inviteHistory);
            }
            catch(ex){
                console.error(ex);
            }
        },

        onGridEvent(eventName, attendee) {
            if(eventName != 'SendInvite') {
                return;
            }

            this.onSendInvites([attendee.Code]);
        },

        updateInviteHistory(inviteHistory) {
            for(var attendee of this.attendees)
            {
                Vue.set(attendee, 'Speaker', false);
                Vue.set(attendee, 'Moderator', false);
                Vue.set(attendee, 'LastSentInvite', null);
            }

            for(var invite of inviteHistory)
            {
                let attendee = this.attendees.find(x => x.Code == invite.AttendeeCode);

                if(!attendee) {
                    continue;
                }

                Vue.set(attendee, 'LastSentInvite', moment(invite.SentDateTime).format("L LT"));
            }

            for(var contactInfo of this.selectedEntity.ContactInfo.ContactInfo.filter(x =>
                x.Type == 'TractusLink' 
                && x.RoleCode
                && x.Value 
                && x.Value.indexOf("//Attendee" != -1))) 
            {
                let code = contactInfo.Value.split('&').find(x=>x.startsWith('c='));

                if(!code) {
                    continue;
                }

                code = code.replace('c=', '');

                let attendee = this.attendees.find(x => x.Code == code);

                if(!attendee) {
                    continue;
                }

                let isSpeaker = contactInfo.RoleCode == "Speaker";
                let isModerator = contactInfo.RoleCode == "Moderator";

                Vue.set(attendee, 'Speaker', isSpeaker);
                Vue.set(attendee, 'Moderator', isModerator);

                this.selectedAttendees.push(attendee.Code);
            }
        },

        async getSessionsAsync(){
            let name = "SESSIONS";
            let r = await this.tryGet(`/api/show/${this.showCode}/sessions`);
            this.sessions = r.Result;
        },

        selectAttendee(attendee) {
            let index = this.selectedAttendees.indexOf(attendee.Code);

            if(index == -1) {
                this.selectedAttendees.push(attendee.Code);
            } else {
                this.selectedAttendees.splice(index, 1);
            }

            attendee.Modified = true;
        },

        onAttendeeEdited(attendee) {
            attendee.Modified = true;            
        },

        async onSendInvites(attendeeCodes) {
            if(!this.selectedEntity) {
                return;
            }

            this.submitting = true;
            this.validateErrors = [];

            try {
                let speakerInvites = {
                    AttendeeCodes: [],
                    ActionTypeCode: 'SpeakerIcalInviteRequest'
                };

                let moderatorInvites = {
                    AttendeeCodes: [],
                    ActionTypeCode: 'ModeratorIcalInviteRequest'
                };

                attendeeCodes = attendeeCodes || this.selectedAttendees;

                attendeeCodes.forEach(x => {
                    let attendee = this.attendees.find(a => a.Code == x);
                    
                    if(!attendee) {
                        return;
                    }

                    if(attendee.Speaker) {
                        speakerInvites.AttendeeCodes.push(x);
                    } else if (attendee.Moderator) {
                        moderatorInvites.AttendeeCodes.push(x);
                    }
                });

                let toSend = [];

                if(speakerInvites.AttendeeCodes.length != 0) {
                    toSend.push(speakerInvites);
                }

                if(moderatorInvites.AttendeeCodes.length != 0) {
                    toSend.push(moderatorInvites);
                }

                if(toSend.length == 0) {
                    return;
                }

                let url = `/api/show/${this.showCode}/sessions/${this.selectedEntity.Code}/invites/send`
                let r = await this.tryPost(url, JSON.stringify(toSend), 'application/json')

                this.updateInviteHistory(r.Result);

                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors = [r.Errors[0]];
                }
            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },

        async build() {
            this.submitting = true;
            this.selectedEntity = null;
            this.selectedAttendees = [];
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/attendees`);
                
                result.Result.forEach(element => {
                    Vue.set(element, 'Speaker', false);
                    Vue.set(element, 'Moderator', false);
                    Vue.set(element, 'LastSentInvite', null);
                });

                this.attendees = result.Result;
            } catch {
                alert("Could not load attendees. Try again later.");
            }

            this.submitting = false;

            Vue.nextTick(() => {
                this.$refs.attendeeList.resetFilters();
            })
        },

    },

    data() {
        return {
            selectedAttendees: [],

            validateErrors: [],
            attendees: [],

            customColumns: [
                {Type: 'Checkbox', Path: 'Speaker', Header: 'Speaker', ReadOnly: true},
                {Type: 'Checkbox', Path: 'Moderator', Header: 'Moderator', ReadOnly: true},
                {Type: 'Text', Path: 'LastSentInvite', Header: 'Last Sent Invite', ReadOnly: true},
                {Type: 'Button', Header: '', EventName: 'SendInvite', Icon: "envelope-fill", Color: "primary"},
            ],

            sessions: [],
            selectedEntity: null,
            sessionDetails: null,
        }
    },

    async created(){
        await this.getSessionsAsync();
    },

    mounted() {
        this.build();
    }
}
</script>