<template>
    <loading v-if="submitting">

    </loading>
    <div class="container-fluid scrolling-columns"
         v-else>
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle form-control" 
                                    type="button"
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                    View {{ filterMode == 'all' ? 'All' : 'User-Generated' }}
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button class="dropdown-item"
                                            type="button"
                                            @click="setFilterMode('all')">
                                        Show all campaigns
                                    </button>
                                    <button class="dropdown-item"
                                            type="button"
                                            @click="setFilterMode('user')">
                                        Show only user-generated
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary me-2"
                                @click="build">
                            Refresh
                        </button>
                        <button class="btn btn-primary"
                                @click="showAddNewCampaign">
                            New...
                        </button>
                    </div>
                </div>

                <table class="table table-hover table-striped table-sm">
                    <thead>
                        <tr>
                            <th>
                                Last Update
                            </th>
                            <th v-if="!selectedCampaign">
                                Subject
                            </th>
                            <th v-if="!selectedCampaign">
                                Recipients
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Auto-Generated
                            </th>
                            <th v-if="!selectedCampaign">
                                Code
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(campaign, idx) in campaigns"
                            class="pe-action"
                            :class="[selectedCampaign && selectedCampaign.Code == campaign.Code ? 'table-primary' : '']"
                            @click="showEditCampaign(campaign, idx)">
                            <td>
                                {{ campaign.LastUpdated | moment('L LT')}}
                            </td>
                            <td v-if="!selectedCampaign">
                                {{ campaign.TemplateSubject }}
                            </td>
                            <td v-if="!selectedCampaign">
                                {{ campaign.RecipientCount }}
                            </td>
                            <td>
                                {{ campaign.StatusCode }}
                            </td>
                            <td>
                                {{ campaign.IsAutoGenerated ? "Yes" : "No" }}
                            </td>
                            <td v-if="!selectedCampaign">
                                {{ campaign.Code }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-9"
                 v-if="selectedCampaign">
                 <notification-editor v-on:saved="onSave" 
                                    v-on:cancel="onCancel"
                                    v-bind:show="showDetails"
                                    v-bind:templates="templates"
                                    v-bind:currentCampaign="selectedCampaign">
                </notification-editor> 
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],    


    methods: {

        showAddNewCampaign(){
            this.selectedCampaign = {
                Code: null,
                StatusCode: 'Draft',
                ScheduledDateTime: null,
                RecipientCount: 0,
                TemplateSubject: null,
                Request: {
                    Code: null,
                    Template: {
                        Code: '',
                        Subject: '',
                        Body: '',
                        Footer: '',
                        Id: "0"
                    },
                    TemplateCode: null,
                    ShowCode: this.showCode,
                    To: [],
                    Replacements: [],
                    ScheduledDateTime: null,
                    AutoGenerated: false
                }
            };
        },

        onSave() {
            this.build();
            this.selectedCampaign = null;
        },

        onCancel() {    
            this.selectedCampaign = null;
        },

        setFilterMode(mode) {
            this.filterMode = mode;
            this.build();
        },

        async showEditCampaign(campaign, idx) {
            try {
                let r = await this.tryGet(`/api/communications/campaigns/${this.showCode}/${campaign.Code}`)

                this.selectedCampaign = null;

                Vue.nextTick(() => {
                    this.selectedCampaign = r.Result;
                })
            } catch(ex) {
                console.error(ex);
            }
        },

        async build() {
            this.submitting = true;
            try{
                let r = await this.tryGet(`/api/communications/templates/${this.showCode}`);
                this.templates = r.Result.sort((a, b) => a.Subject > b.Subject ? 1 : a.Subject < b.Subject ? -1 : 0);

                r = await this.tryGet(`/api/communications/campaigns/${this.showCode}?m=${this.filterMode}`);
                this.campaigns = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
        },
    },

    data() {
        return {
            templates: [],
            campaigns: [],
            selectedCampaign: null,
            filterMode: 'user'
        }
    },

    created: function() {
        this.build();
    },
}
</script>