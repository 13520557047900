<template>
    <div>
        <div v-if="submitting">
            <loading></loading>
        </div>
        <div v-if="poll && !viewInvites">
            <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                <div class="col">
                    <input type="text" 
                            placeholder="Search..." 
                            class="form-control"
                            v-model="searchTerm">                    
                </div>
                <div class="col-auto">
                    <div class="d-flex align-items-center h-100">
                        {{ poll.Responses.length }} responses
                    </div>
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary"
                            @click="emitClose">
                        Close
                    </button>
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary"
                            @click="viewInvites = true">
                        Invites
                    </button>
                </div>
                <div class="col-auto">
                    <div class="btn-group">
                        <button type="button"
                                class="btn btn-primary"
                                @click="onDownloadRequest">
                            <i class="bi bi-download"></i>
                        </button>

                        <button type="button"
                                class="btn btn-primary"
                                @click="downloadPrimaryEmails">
                            <i class="bi bi-envelope"></i>
                        </button>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-group">
                        <button type="button"
                                class="btn btn-primary"
                                @click="$emit('editRequest')">
                            <i class="bi bi-pencil-square"></i>
                        </button>
                    </div>
                </div>
                <div class="col-auto">
                    <button type="button"
                            class="btn btn-danger"
                            @click="$emit('deleteItem', poll)">
                        <i class="bi bi-trash"></i>
                    </button>
                </div>
            </div>


            <template v-if="searchTerm">
                <div class="row" v-if="searchResults.length == 0">
                    <div class="col mt-1 mb-1">
                        <div class="alert alert-warning">No results found.</div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col mt-1 mb-1">
                        <div class="list-group">
                            <button v-for="result in searchResults"
                                    class="list-group-item list-group-item-action"
                                    :class="{'active' : selectedResponse && selectedResponse.Id == result.Id}"
                                    @click="setSelectedResponse(result.PollResponse, true)"
                                    type="button">
                                    <h5>{{result.Question}}</h5>
                                    {{result.Response}}
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="poll.Responses.length > 0">
                <table class="table table-hover table-striped tPollInviteListable-sm table-responsive response-table">
                    <thead class="atn-header border-bottom border-dark bg-body-tertiary sticky-top">
                        <tr>
                            <th v-for="question in tableQuestions">
                                {{ question.Name }}
                            </th>
                            <th>
                                Code
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="response in poll.Responses">
                            <tr @click="setSelectedResponse(response)" :id="`response_${response.Id}`">
                                <td v-for="question in tableQuestions">
                                    <div class="btn-group" v-if="question.QuestionType == 'ImageUpload' || question.QuestionType == 'LargeUpload'">
                                        <a class="btn btn-outline-primary btn-sm"
                                            @click.stop
                                            :href="formatQuestionOutput(question.Code, response)"
                                            target="_blank">
                                            <i class="bi bi-download">

                                            </i>
                                        </a>
                                        <button class="btn btn-outline-primary btn-sm"
                                            @click.stop="copyLinkToClipboard(question.Code, response)"
                                            target="_blank">
                                            <i class="bi bi-clipboard">

                                            </i>
                                        </button>
                                    </div>
                                    <span v-else-if="question.MappedTo == 'CONTACT_Email'">
                                        <a class="d-block-inline" @contextmenu.prevent="copyLinkToClipboard(question.Code, response)"  @click.stop :href="`mailto:${formatQuestionOutput(question.Code, response)}`">{{ formatQuestionOutput(question.Code, response) }}</a>
                                    </span>
                                    <span v-else>
                                        {{ formatQuestionOutput(question.Code, response) }}
                                    </span>
                                    </td>
                                <td>
                                    {{ response.Id }}
                                </td>
                            </tr>
                            <tr v-if="selectedResponse && selectedResponse.Id == response.Id">
                                <td colspan="999">
                                    <div>
                                        <div class="d-flex">
                                            <div class="btn-group me-2">
                                                <a class="btn btn-outline-primary"
                                                    :href="pollLink(response)"
                                                    target="_blank">
                                                    <i class="bi bi-pencil"></i>
                                                </a>
                                                <router-link :to="{path: `/shows/${showCode}/topage/${poll.Code}/${selectedResponse.Id}`}"
                                                            class="btn btn-outline-primary">
                                                    <i class="bi bi-menu-app-fill"></i>
                                                </router-link>
                                            </div>
                                            <div class="btn-group me-2">
                                                <button type="button" 
                                                        class="btn btn-outline-primary" 
                                                        @click="resendResponseEmail(response)" >
                                                    <i class="bi bi-envelope"></i> Resend Response
                                                </button>
                                                <button type="button" 
                                                        class="btn btn-outline-primary" 
                                                        @click="resendWelcomeEmail(response)">
                                                    <i class="bi bi-envelope"></i> Resend Welcome
                                                </button>
                                            </div>
                                            <div class="btn-group">
                                                <button class="btn btn-danger"
                                                    role="button"
                                                    @click="requestDeletePollResponseAsync(false)">
                                                    Delete Response
                                                </button>
                                                <button class="btn btn-danger"
                                                    role="button"
                                                    @click="requestDeletePollResponseAsync(true)">
                                                    Delete Response and Linked Entities
                                                </button>
                                            </div>
                                        </div>

                                        <template v-if="links.length > 0">
                                            <h5 class="mt-2">
                                                Linked Entities
                                            </h5>
                                            <table class="table table-responsive w-auto">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Type
                                                        </th>
                                                        <th>
                                                            Code
                                                        </th>
                                                        <th>
                                                            Name
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="link in links">
                                                        <td>
                                                            {{ link.EntityTypeCode }}
                                                        </td>
                                                        <td>
                                                            {{ link.EntityCode }}
                                                        </td>
                                                        <td>
                                                            {{ getLinkText(link) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>

                </table>



                <div class="row">
                    <div class="col">
                    </div>
                </div>               
            </div>
        </div>      
        <div v-else-if="poll && viewInvites">
            <poll-invite-list :pollCode="pollCode"
                              :showCode="showCode"
                              :show="show"
                              :poll="poll"
                              @cancel="viewInvites = false" />
        </div>
    </div>
</template>
<style scoped>
img.table-preview {
    max-width: 64px;
}
</style>
<script>
import Vue from 'vue'

import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import { getPollAppUrl } from './environment.hci';
import ExcelJS from 'exceljs'
import PollInviteList from './poll.invite.list.vue'

export default {
    props: ['pollCode','showCode','show'],
    
    components: {
        'poll-invite-list': PollInviteList
    },

    mixins: [
        Token,
        Common
    ],

    computed: {
        tableQuestions() {
            return this.poll.Questions.filter(x => 
                x.QuestionType != 'TextPrompt'
                && x.QuestionType != 'GroupInsertButton'
                && !(x.GroupTag && !x.SubGroup));
        },

        searchResults() {
            if(!this.searchTerm || this.searchTerm.length == 0) {
                return [];
            }

            let results = [];
    
            let searchRegex = new RegExp(this.searchTerm, 'i');

            this.poll.Responses.forEach(pr => {
                for(let i = 0; i < pr.Questions.length; i++) {
                    let question = pr.Questions[i];

                    if(!question.Response) {
                        continue;
                    }

                    if(searchRegex.test(question.Response)) {
                        let result = {
                            Id: pr.Id,
                            PollResponse: pr,
                            Index: this.poll.Responses.indexOf(pr),
                            Question: question.Name,
                            Response: question.Response
                        }

                        results.push(result);
                    }
                }
            });

            return results;
        },
    },

    methods: {
        emitClose() {
            this.$emit('close');
        },
        
        pollLink: function(poll) {
            return `${getPollAppUrl()}/#/pp/${this.poll.ShowCode}/${this.poll.Code}?r=${poll.Id}`;
        },


        copyLinkToClipboard(questionCode, response) {
            if(!response) {
                return;
            }

            console.log(response);
            let question = response.Questions.find(x => x.Code == questionCode);

            if(!question || !question.Response) {
                return;
            }

            navigator.clipboard.writeText(question.Response);

            this.showToast('Copied link to clipboard.');
        },

        formatQuestionOutput(code, response) {
            if(!response) {
                return 'NF';
            }
            
            let question = response.Questions.find(x => x.Code == code);

            if(!question || !question.Response) {
                return '';
            }

            if(this.isMultipleChoiceQuestion(question)) {
                let responseText = question.Response;   
                let response = question.Answers.find(x => x.Response == responseText);

                if(!response) {
                    return responseText;
                }

                return response.DisplayText;
            }

            return question.Response;
        },

        setSelectedResponse(poll, doScroll) {

            this.selectedResponse = poll;
            this.retrievePollResponseLinks();

            if(doScroll && poll) {
                let row = document.getElementById(`response_${poll.Id}`);
                row.scrollIntoView();
            }
        },

        async requestDeletePollResponseAsync(includeEntities) {
            if(!this.selectedResponse) {
                return;
            }

            const message = includeEntities
                ? "Are you sure you want to delete this response AND all associated entities? This cannot be undone."
                : "Are you sure you want to delete this poll response? This cannot be undone.";

            let result = confirm(message);

            if(!result) {
                return;
            }

            try{

                let r = await this.tryDelete(`/api/poll/${this.showCode}/${this.poll.Code}/${this.selectedResponse.Id}?l=${includeEntities}`);
                if(r.Errors.length !== 0) {
                    return;
                }

                this.loadPoll();
            }
            catch(ex){
                console.log(ex);
            }


        },

        isInMultipleAnswer(question, r){
            if(question.Response.indexOf('|')){
                return question.Response.split("|").includes(r.Response);
            }
            return false;
        },

        async resendWelcomeEmail() {
            console.log("RESEND")
            this.submitting = true;
            
            try {
                let uri = `/api/poll/welcomeemail/send/${this.showCode}/${this.poll.Code}/${this.selectedResponse.Id}`;
                console.log(uri);

                await this.tryPost(
                    uri,
                    JSON.stringify({}), 
                    'application/json');

                this.showToast('Re-sent welcome email.');

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },

        async resendResponseEmail() {
            console.log("RESEND")
            this.submitting = true;
            
            try {
                let uri = `/api/poll/email/send/${this.showCode}/${this.poll.Code}/${this.selectedResponse.Id}`;
                console.log(uri);

                await this.tryPost(
                    uri,
                    JSON.stringify({}), 
                    'application/json');

                this.showToast('Re-sent response email.');

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },        

        parseCommand(e) {
            let key = e.key;

            let ctrlModifier = e.ctrlKey;

            let altModifier = e.altKey;

            let shiftModifier = e.shiftKey;

            if(this.poll.Responses.length == 0) {
                return;
            }

            if(!this.selectedResponse) {
                return;
            }

            // if (key == 'ArrowDown' && ctrlModifier) {
            //     let movement = shiftModifier ? this.poll.Responses.length : 1;
            //     this.nextResponse(movement);
            //     e.preventDefault();
            // } else if (key == 'ArrowLeft' && ctrlModifier) {
            //     let movement = shiftModifier ? this.poll.Responses.length : 1;
            //     this.nextResponse(-movement);
            //     e.preventDefault();
            // } 
        },

        getLinkText(link) {
            if(link.EntityTypeCode == 'SESSION') {
                let session = this.tractusLinkDestinations['SESSIONS'].find(x => x.Code == link.EntityCode);
                
                return session ? `${session.EditorName}` : `Unknown Session ${link.EntityCode}`;
                
            } else if (link.EntityTypeCode == 'VENDOR') {
                let vendor = this.tractusLinkDestinations['VENDORS'].find(x => x.Code == link.EntityCode);

                return vendor ?  `${vendor.DisplayName} (Page)` :  `Unknown Page ${link.EntityCode}`;

            } else if (link.EntityTypeCode == "ATTENDEE") {
                let attendee = this.tractusLinkDestinations['ATTENDEES'].find(x => x.Code == link.EntityCode);

                return attendee ? `${attendee.DisplayName} (${attendee.Code})` : `Unknown Attendee ${link.EntityCode}`;
            }
        }, 

        isRankedChoiceQuestion(question){
            return !this.isQuestionGroupTemplate(question) 
                &&  question.QuestionType == 'RankedChoice';
        },

        isStarQuestion(question){
            return !this.isQuestionGroupTemplate(question) 
                &&  question.QuestionType == 'Ranking';
        },

        isFileQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'ImageUpload'
                    || question.QuestionType == 'LargeUpload');

        },

        isMultipleChoiceQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'MultipleChoice'
                    || question.QuestionType == 'MultipleChoiceMultiSelect'
                    || question.QuestionType == 'MultipleChoiceDropdown');
        },

        isTextDisplayQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'SingleLineText'
                    || question.QuestionType == 'FreeformText');
        },

        isLabelDisplayQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && question.QuestionType == 'TextPrompt';
        },

        isQuestionGroupTemplate(question) {
            return question.GroupTag && !question.SubGroup;
        },


        async onDownloadRequest() {
            let book = new ExcelJS.Workbook();
            let sheet = book.addWorksheet('Index');

            let rows = [];

            let columns = [
                'Code',
                'Submitted',
            ];

            let questions = this.poll.Questions.filter(x => 
                !x.GroupTag
                && !x.SubGroup
                && x.QuestionType != 'GroupInsertButton'
                && x.QuestionType != 'Html'
                && x.QuestionType != 'TextPrompt'
            );

            console.log(questions);

            for(let i = 0; i < questions.length; i++) {
                columns.push(questions[i].Name);
            }

            sheet.addRow(columns);

            let toWrite = this.poll.Responses;

            for(let i = 0; i < toWrite.length; i++) {
                let poll = toWrite[i];
                let responseSheet = book.addWorksheet(`${toWrite.length - i}`);

                responseSheet.addRow(['Question', 'Response']);

                let rowValues = [];
                rowValues.push(poll.Id);
                rowValues.push(poll.SubmittedDateTime);

                responseSheet.addRow(['Code', poll.Id]);
                responseSheet.addRow(['Submitted At', poll.SubmittedDateTime]);

                for(let j = 0; j < questions.length; j++) {
                    let question = questions[j];

                    let found = poll.Questions.find(x => x.Code == question.Code);

                    let valueToWrite = '';
                    if(found) {
                        valueToWrite = found.Response;
                    }
                    rowValues.push(valueToWrite);
                }

                sheet.addRow(rowValues);

                let allQuestionsForResponse = poll.Questions.filter(x => 
                    ((!x.GroupTag && !x.SubGroup) || x.SubGroup) 
                    && x.QuestionType != 'GroupInsertButton'
                    && x.QuestionType != 'Html'
                    && x.QuestionType != 'TextPrompt'
                    && x.QuestionType != 'GroupDeleteButton'
                );

                console.log(allQuestionsForResponse);

                for(let j = 0; j < allQuestionsForResponse.length; j++) {
                    let question = allQuestionsForResponse[j];
                    responseSheet.addRow([question.Name, question.Response || '']);
                }

                responseSheet.getCell('A1').font = {
                    bold: true
                };
                responseSheet.getCell('B1').font = {
                    bold: true
                };
                responseSheet.columns.forEach((column, i) => {
                    let maxLength = 0;
                    column["eachCell"]({ includeEmpty: true }, function (cell) {
                        var columnLength = cell.value ? cell.value.toString().length : 10;
                        if (columnLength > maxLength ) {
                            maxLength = columnLength + 5;
                        }
                    });
                    column.width = maxLength < 10 ? 10 : maxLength;
                });
            }

            sheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength + 5;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });

            for(let i = 0; i < columns.length; i++) {
                sheet.getCell(`${String.fromCharCode('A'.charCodeAt() + i)}1`).font = {
                    bold: true
                };
            }

            sheet.autoFilter = {
                from: 'A1',
                to: `${String.fromCharCode('A'.charCodeAt() + columns.length - 1)}1`
            };

            console.log(sheet.autoFilter);

            let buffer = await book.xlsx.writeBuffer();

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            let blob = new Blob([buffer], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            link.download = `Results_${moment().format('YYYY_MM_DD_HH_mm')}_${this.showCode}_${this.poll.Name}.xlsx`;

            link.click();
            document.removeChild(link);

        },

        async downloadPrimaryEmails() {


            try{
                let r = await this.tryGetText(`/api/polls/${this.showCode}/${this.pollCode}/exportemails`);

                navigator.clipboard.writeText(r);

                this.showToast('Copied emails to clipboard.');
            }
            catch(ex){
                console.log(ex);
            }
        },

        async loadPoll(showCode,pollCode){
            this.submitting=true;

            try{
                let r = await this.tryGet('/api/polls/'+this.showCode+'/'+this.pollCode)
                
                this.poll = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting=false;
        },

        async retrievePollResponseLinks() {
            this.links = [];
            if(!this.selectedResponse) {
                return;
            }

            try {
                let url = `/api/pollresponselinks/${this.showCode}/${this.poll.Code}/${this.selectedResponse.Id}`;
                let result = await this.tryGet(url);

                this.links = result.Result || [];
            } catch(ex) {
                console.error(ex);
            }
        },        
    },

    data(){ 
        return{           
            responseIndex: 0,
            selectedResponse: null,
            viewInvites: false,

            poll: null,
            forceRefreshSelectedResponse: false,
            searchTerm: null,
            links: [],
        }
    },

    destroyed() {
        window.removeEventListener('keydown', this.parseCommand)
    },

    async created(){
        await this.getTractusLinkDestinationsAsync(this.showCode);
        window.addEventListener('keydown', this.parseCommand);
        this.loadPoll(this.showCode,this.pollCode);
    }
}
</script>