<template>
<div class="container-fluid scrolling-columns">
    <div class="row">
        <div class="col-8">
            <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                <div class="col">
                    <h5>Theme Editor</h5>
                </div>
                <div class="col-auto">
                    <button type="button" 
                            class="btn btn-primary mb-2"
                            @click="genericDoShowSave">Save</button>
                    <button type="button" 
                            class="btn btn-warning mb-2"
                            data-bs-toggle="modal"
                            data-bs-target="#resetStyle">
                        Reset
                    </button>

                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-theme-editor :show="show" 
                       v-bind:stylesheet.sync="show.Theme.StyleSheet"
                       ref="appTheme">

                    </app-theme-editor>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <textarea v-model="show.Theme.StyleSheet"
                              class="form-control vh-75 style-editor" 
                              :disabled="submitting"></textarea>

                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>
                        Home Backgrounds
                    </label>
                    <button type="button" @click.prevent="addBackground" class="btn btn-primary">Add...</button>
                    <div class="container">
                        <div v-for="(background, ix) in show.Theme.HomeBackgrounds">
                            <file-picker :ref="'picker' + ix" 
                                            @fileSelected="onSaveShowBgComplete(ix, $event)" 
                                            :showCode="show.Code"
                                            type="image"
                                            :imagePath="background"/>

                            <img v-if="background" @click="onChooseBackgroundImage(ix)" :src="background" class="img-fluid palette-image-preview">
                            <div v-else class="no-image-preview" @click="onChooseBackgroundImage(ix)">
                                No Image Selected
                            </div>
                            
                            <i @click="removeBackground(ix)" class="ms-auto bi bi-x-circle"></i> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade text-dark" 
             id="resetStyle">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        Reset Style
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <p>This will reset ALL custom colors. Are you sure?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                            class="btn btn-secondary" 
                            data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                            @click="reset">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<style scoped>
.palette-image-preview {
    max-width: 250px;
    max-height: 175px;
}
</style>
<script>
import Token from './authentication/token'
import Common from './common'
import Vue from 'vue';

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common,
    ],   

    watch:{
        show: {
            deep: true,
            handler() {
                this.bus.$emit('Update-Show-Preview');
            }
        },
    },

    methods: {
        reset(){
            this.$refs.appTheme.reset();
        },
        onChooseBackgroundImage(ix){
            this.$refs['picker' + ix][0].openPicker();
        },
        async genericDoShowSave() {
            this.submitting = true;

            var toSave = JSON.parse(JSON.stringify(this.show));

            try{
                await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
            
                this.bus.$emit('Do-Show-Reload');
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },

        removeBackground: function(ix) {
            this.show.Theme.HomeBackgrounds.splice(ix, 1);
        },

        onSaveShowBgComplete: function(ix, result) {
            this.show.Theme.HomeBackgrounds.splice(ix, 1, result);
        },

        addBackground: function() {
            this.show.Theme.HomeBackgrounds.push("");
        },

    },

    data() {
        return {
            show: null
        }
    },

    created: function() {
        this.show = this.showDetails;

        console.log(this.show);

        if(!this.show.Theme) {
            Vue.set(this.show, "Theme", {
                HomeBackgrounds: [],
                StyleSheet: '' 
            });
        }

        if(!this.show.Theme.StyleSheet) {
            Vue.set(this.show.Theme, 'StyleSheet', '');
        }

        if(!this.show.Theme.HomeBackgrounds) {
            Vue.set(this.show.Theme, 'HomeBackgrounds', []);
        }
    }    
}
</script>