import { getApiUrl } from '../environment.hci'

const ERR_PROMISE_REJECT_500 = "We weren't able to connect to the server. Please try again later.";
const HTTP_PATTERN = /^((http|https|ftp):\/\/)/;

export function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
}

export function getIdToken() {
    return JSON.parse(localStorage.getItem('currentUser'));
}


export function isLoggedIn() {
    const idToken = getIdToken();
    return !!idToken;
}

export default {
    methods: {

        logOut: function () {
            var path = '/login';

            localStorage.clear();
            this.$router.push(path);
            this.$emit("auth:changed", false);
        },

        tryGetBinary: async function (path) {
            var currentDetails = this.getUserDetails();
            var headers = {};
            headers['Authorization'] = 'Bearer ' + currentDetails.token;

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryGetBinary(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.blob();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },

        tryGetText: async function (path) {
            var currentDetails = this.getUserDetails();
            var headers = {};
            headers['Authorization'] = 'Bearer ' + currentDetails.token;

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryGetText(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.text();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },

        async tryGet(path) {

            var currentDetails = this.getUserDetails();
            var headers = {
                'Accept': 'application/json'
            };

            headers['Authorization'] = 'Bearer ' + currentDetails.token;

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if(!result) {
                                this.$router.push('/login');
                                return null;
                            } 

                            return await this.tryGet(path);
                        } catch(ex) {
                            this.$router.push('/login');
                            return null;
                        }

                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }
                return r;

            }
            catch (err) {
                return {
                    Errors: [ERR_PROMISE_REJECT_500],
                    ErrorCodes: ['ERR_500'],
                    Result: null
                };
            }
        },

        async tryDelete(path) {

            var currentDetails = this.getUserDetails();

            var headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'delete',
                        credentials: 'include',
                        headers: headers,
                    });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryDelete(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (ex) {
                return {
                    Errors: [r.statusText],
                    ErrorCodes: ['ERR_' + r.status],
                    Result: null
                };
            }
        },

        async tryPut(path) {

            var currentDetails = this.getUserDetails();

            var headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'put',
                        credentials: 'include',
                        headers: headers,
                    });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryDelete(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (ex) {
                return {
                    Errors: [r.statusText],
                    ErrorCodes: ['ERR_' + r.status],
                    Result: null
                };
            }
        },

        async tryRefreshToken() {
            console.warn("REFRESHING TOKEN------")
            let expiredToken = getIdToken().token;
            let userName = getIdToken().username;

            try {
                let r = await fetch(`${getApiUrl()}/api/token/refresh`, {
                    mode: 'cors',
                    method: 'post',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ExpiredToken: expiredToken })
                });

                try {
                    r = await r.json();
                }
                catch (ex) {
                    this.$router.push('/login');
                    return null;
                }

                if (!r.Result) {
                    this.$router.push('/login');
                    return null;
                }

                this.authenticated = true;
                this.$emit("auth:changed", true);
                localStorage.setItem("currentUser", JSON.stringify({
                    username: userName,
                    token: r.Result
                }));

                return r;
            }
            catch (err) {
                return null;
            }
        },

        async tryPost(path, data, contentType, redirectToLoginOnFail = true) {
            var currentDetails = this.getUserDetails();

            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (contentType) {
                headers['Content-Type'] = contentType;
            }

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'post',
                        headers: headers,
                        body: data
                    });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryPost(path, data, contentType, redirectToLoginOnFail);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                    return r;
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }
            }
            catch (ex) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        async tryGetAnonymous(path) {

            var original = this;

            var headers = {
                'Accept': 'application/json',
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            return null;
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },        

        getUserDetails: function () {
            return getIdToken();
        },

        async tryLogin(userName, password) {

            var request = {
                TypeCode: 'ConsoleUser',
                Elements: [
                    {
                        Code: 'UserName',
                        Value: userName
                    },
                    {
                        Code: 'Password',
                        Value: password
                    }
                ]
            };

            try {
                let r = await fetch(getApiUrl() + '/api/token',
                    {
                        mode: 'cors',
                        method: 'post',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(request)
                    });

                if (!r.ok && r.status !== 400) {
                    this.$emit("auth:changed", false);
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                    this.$emit("auth:changed", true);
                    this.authenticated = true;
                    localStorage.setItem("currentUser", JSON.stringify({
                        username: userName,
                        token: r.Result
                    }));
                    return r;
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

            }
            catch (ex) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

    }
}
