<template>
    <div>
        <div v-if="submitting">
            <loading></loading>
        </div>
        <nav class="navbar">
            <div class="container-fluid">
                <h1 class="navbar-brand">
                    {{showDetails.Name}}
                </h1>
                <div>
                    <div class="btn-group me-2">
                        <button @click="mode = 'Schedule'"
                                class="btn btn-primary">
                            View Schedule
                        </button>
                        <button @click="mode = 'Location'"
                                class="btn btn-primary">
                            View Locations
                        </button>
                    </div>
                    <button class="btn btn-primary"
                            @click="printRundown">
                        Print
                    </button>
                </div>
            </div>
        </nav>
        <div ref="mainTable"
             v-if="mode == 'Location'">

             <div class="mt-2" 
                v-for="([day,sessionDayInfo],idx) in sessionsByLocation"
                :class="[idx > 0 ? 'always-page-break-before' : '']">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th colspan="5"
                                style="text-align: center; font-size: 20px;">
                                {{ day || '(No Location)' }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Time
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Category
                            </th>
                            <th>
                                Speaker(s)
                            </th>
                            <th>
                                Notes
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="session in sessionDayInfo"
                            style="break-inside: avoid">
                            <td v-if="!useShowTz">
                                {{ session.start | moment('ddd MMM Do') }}<br>
                                {{session.start | moment('h:mm a')}} - {{session.end | moment('h:mm a')}}
                                <div>
                                    <strong>{{session.duration}} min</strong>
                                </div>

                            </td>
                            <td style="width: 2.2in" v-else>
                                <!-- s - {{session}} -->
                                <i>{{ momentFormatTz(session.start, 'ddd MMM Do') }}</i><br>
                                {{ momentFormatTz(session.start, 'h:mm a')}} - {{momentFormatTz(session.end, 'h:mm a z')}}<br>
                                {{ momentFormatLocalTz(session.start, 'h:mm a')}} - {{momentFormatLocalTz(session.end, 'h:mm a z')}}
                                <div>
                                    <strong>{{session.duration}} min</strong>
                                </div>
                            </td>
                            <td style="max-width: 2in">
                                {{session.name}}<br>
                                <strong>{{ session.location }}</strong>
                            </td>
                            <td style="max-width: 1in">
                                {{session.category}}
                            </td>
                            <td style="max-width: 2in;">
                                <strong>{{ session.speakers.length }} speaker(s)</strong>
                                <div v-for="speaker in session.speakers">
                                    {{speaker.DisplayText}}
                                </div>
                            </td>
                            <td style="min-width: 2in">
                                <div>
                                    <textarea class="form-control form-control-sm h-100">

                                    </textarea>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div ref="mainTable"
             v-if="mode == 'Schedule'">
            <div class="mt-2" 
                v-for="([day,sessionDayInfo],idx) in sessions" >
                <h1 :class="[idx > 0 ? 'always-page-break-before' : '']">
                    {{day}}
                </h1>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>
                                Time
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Category
                            </th>
                            <th>
                                Speaker(s)
                            </th>
                            <th>
                                Notes
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="session in sessionDayInfo"
                            style="break-inside: avoid">
                            <td style="max-width: 1.25in">
                                <!-- s - {{session}} -->
                                {{session.start | moment('h:mm a')}} - {{session.end | moment('h:mm a')}}
                                <div>
                                    <strong>{{session.duration}} min</strong>
                                </div>
                            </td>
                            <td style="max-width: 3in">
                                {{session.name}}<br>
                                <strong>{{ session.location }}</strong>
                            </td>
                            <td style="max-width: 2in">
                                {{session.category}}
                            </td>
                            <td>
                                <strong>{{ session.speakers.length }} speaker(s)</strong>
                                <div v-for="speaker in session.speakers">
                                    {{speaker.DisplayText}}
                                </div>
                            </td>
                            <td style="min-width: 2in">
                                <div>
                                    <textarea class="form-control form-control-sm h-100">

                                    </textarea>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>      
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment-timezone'
import ExcelJS from 'exceljs'

// Total copy-paste from clientapp.
function momentFormat(value, format) {
    let toReturn = moment(value);

    if(format) {
        return toReturn.format(format);
    }

    return toReturn;
}

console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

function momentFormatTz(value, format, showTimeZone) {
    try {
        let tz = '';
        //tz = "America/Los_Angeles";
        if(showTimeZone) {
            tz = showTimeZone;
        }
    
        if(!tz) {
            console.warn("No timezone added...");
            return momentFormat(value, format);
        }
    
        let toReturn = moment(value);
    
        if(format) {
            return toReturn.tz(tz).format(format);
        }
    
        return toReturn;    
    } catch(ex) {
        return momentFormat(value, format);
    }
}

function momentFormatLocalTz(value, format) {
    try {
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if(!tz) {
            console.warn("No timezone added...");
            return momentFormat(value, format);
        }
    
        let toReturn = moment(value);
    
        if(format) {
            return toReturn.tz(tz).format(format);
        }
    
        return toReturn;    
    } catch {  
        return momentFormat(value, format);
    }
}

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    computed: {
        useShowTz() {
            return this.showDetails.ShowTimeZone &&
                this.showDetails.ShowTimeZone != Intl.DateTimeFormat().resolvedOptions().timeZone;            
        }
    },

    methods: {
        momentFormatTz(value, format) {
            return momentFormatTz(value, format, this.showDetails.ShowTimeZone);
        },

        momentFormatLocalTz(value, format) {
            return momentFormatLocalTz(value, format);
        },

        printRundown(){
            let innerContent = this.$refs.mainTable;
            
            let newWindow = window.open("", "_blank");
            newWindow.document.body.innerHTML = 
                this.template.replaceAll("{{showName}}", this.showDetails.Name)
                        .replace('{{mainTable}}', innerContent.innerHTML);
        },
        groupBy(list, keyGetter) {
            let map = new Map();
            list.forEach((item) => {
                let key = keyGetter(item);
                let collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        getDuration(session) {
            let duration = moment.duration(moment(session.SessionEndDateTime).diff(moment(session.SessionStartDateTime)));
            
            return duration.asMinutes();
        },
        async getSessionsForShow() {
            this.sessions = [];

            try {
                // let attendees = await this.tryGet(`/api/show/${this.showCode}/attendees`);
                // this.attendees = attendees.Result;


                let url = `/api/show/${this.showCode}/sessions`;
                let result = await this.tryGet(url);

                let results = [];

                for(const [k, sessions] of Object.entries(result.Result))
                {
                    results.push(...sessions.map(s => {
                        let toReturn = {
                            date: moment(s.SessionStartDateTime).format('dddd, MMMM Do YYYY'),
                            duration: this.getDuration(s),
                            start: new Date(s.SessionStartDateTime),
                            end: new Date(s.SessionEndDateTime),
                            name: s.Name,
                            location: s.Location,
                            category: this.showDetails.SessionCategories.find(x => x.Code == s.Category.Code)?.Name,
                            speakers: s.ContactInfo.ContactInfo.filter(x => (x.RoleCode == null || x.RoleCode == "Speaker") && x.Value.startsWith('tractus://ATTENDEE')),
                            moderators: s.ContactInfo.ContactInfo.filter(x => x.RoleCode == "Moderator" && x.Value.startsWith('tractus://Attendee'))
                        };

                        return toReturn;
                    }))
                }

                results =  results.sort((a, b) => {
                    return moment(a.start) - moment(b.start)
                });

                this.sessions = this.groupBy(results, e => e.date);

                this.sessionsByLocation = this.groupBy(results, e => e.location);
                
            } catch(ex) {
                console.error(ex);
            }
        },        
    },

    data(){ 
        return{          
            mode: 'Location', 
            sessions: [],
            sessionsByLocation: [],
            template: `<!DOCTYPE html><html lang="en"> <head> <meta charset="utf-8"/> <meta name="viewport" content="width=device-width, initial-scale=1"/> <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous"/> <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"/> <style>textarea{border:0!important;background:transparent!important;}.always-page-break-before{page-break-before: always;}</style> <title>{{showName}}</title> </head> <body><nav class="navbar"><div class="container-fluid"><h1 class="navbar-brand">{{showName}}</h1></div></nav><div class="p-2">{{mainTable}}</div></body></html>`
        }
    },

    async created(){
        // await this.getTractusLinkDestinationsAsync(this.showCode);
        // console.log(this.tractusLinkDestinations);
        await this.getSessionsForShow();
    }
}
</script>